import {
	get
} from '../utils/http'
const server = {
	// 专业详情
	getProfessionApi(id) {
		return get({
			url: '/system/profession/dp/' + id,
			method: 'get',
		})
	},
	//带参数的 get 方法
	// 就业
	getSendAndPush(param) {
		return get({
			url: '/screen/statistic/employment/sendAndPush',
			method: 'get',
			params: param
		})
	},
	getPushNum(param) {
		return get({
			url: '/screen/statistic/employment/push',
			method: 'get',
			params: param
		})
	},
	getSendNum(param) {
		return get({
			url: '/screen/statistic/employment/sendAmount',
			method: 'get',
			params: param
		})
	},
	getSend(param) {
		return get({
			url: '/screen/statistic/employment/send',
			method: 'get',
			params: param
		})
	},
	getCompanyNum() {
		return get({
			url: '/screen/statistic/company/allAmount',
			method: 'get',
			// params: param
		})
	},
	getGraduate(param) {
		return get({
			url: '/screen/statistic/employment/graduate',
			method: 'get',
			params: param
		})
	},
	getGraduateDetail(param) {
		return get({
			url: '/screen/statistic/employment/graduateDetail',
			method: 'get',
			params: param
		})
	},
	getArea(param) {
		return get({
			url: '/screen/statistic/employment/jobStatistic/area',
			method: 'get',
			params: param
		})
	},
	getSalary(param) {
		return get({
			url: '/screen/statistic/employment/jobStatistic/salary',
			method: 'get',
			params: param
		})
	},
	getProfession(param) {
		return get({
			url: '/screen/statistic/employment/jobStatistic/profession',
			method: 'get',
			params: param
		})
	},

	// 首页

	getEmployment(param) {
		return get({
			url: '/screen/statistic/graduate/employment',
			method: 'get',
			params: param
		})
	},
	getAssetCount(param) {
		return get({
			url: '/screen/statistic/asset',
			method: 'get',
			params: param
		})
	},
	getOutList(param) {
		return get({
			url: '/system/excellent/outList',
			method: 'get',
			params: param
		})
	},
	getResource(param) {
		return get({
			url: '/screen/statistic/resource',
			method: 'get',
			params: param
		})
	},
	getTraining(param) {
		return get({
			url: '/screen/statistic/training',
			method: 'get',
			params: param
		})
	},
	getScientific(param) {
		return get({
			url: '/screen/statistic/scientific',
			method: 'get',
			params: param
		})
	},

	getCertificate(param) {
		return get({
			url: '/screen/school/record/certificate',
			method: 'get',
			params: param
		})
	},
	getCompete(param) {
		return get({
			url: '/screen/school/compete',
			method: 'get',
			params: param
		})
	},
	getStudent(param) {
		return get({
			url: '/screen/school/student',
			method: 'get',
			params: param
		})
	},
	getTeacher(param) {
		return get({
			url: '/screen/school/teacher',
			method: 'get',
			params: param
		})
	},
	getCooperation(param) {
		return get({
			url: '/screen/school/cooperation',
			method: 'get',
			params: param
		})
	},
	getInternshipNum(param) {
		return get({
			url: '/screen/school/internship/count',
			method: 'get',
			params: param
		})
	},



	// 实习

	getInternshipAndSx(param) {
		return get({
			url: '/screen/school/internshipAndSx',
			method: 'get',
			params: param
		})
	},
	getInternshipStu(param) {
		return get({
			url: '/screen/school/internship/student',
			method: 'get',
			params: param
		})
	},
	getStuType(param) {
		return get({
			url: '/screen/school/student/type',
			method: 'get',
			params: param
		})
	},
	getRecommend(param) {
		return get({
			url: '/screen/school/internship/recommend',
			method: 'get',
			params: param
		})
	},

	getInSchool(param) {
		return get({
			url: '/screen/school/inSchoolPeriod',
			method: 'get',
			params: param
		})
	},
	getOutSchool(param) {
		return get({
			url: '/screen/school/outSchoolPeriod',
			method: 'get',
			params: param
		})
	},

	getInternshipInfo(param) {
		return get({
			url: '/screen/school/internshipInfo',
			method: 'get',
			params: param
		})
	},

	getClock(param) {
		return get({
			url: '/screen/school/internshipClock',
			method: 'get',
			params: param
		})
	},










	//不带参数的 get 方法
	// getAll() {
	// 	return get({
	// 		url: '/role/getAll',
	// 		method: 'get'
	// 	})
	// },
	// // post 方法
	// save(param) {
	// 	return post({
	// 		url: '/role/save',
	// 		method: 'post',
	// 		data: param
	// 	})
	// }
}

export default server
