<template>
	<div class="chart-container">
		<div class="chartsGl" style="width: 100%;height: 100%;" ref="chart"></div>
		<div class="title">
			<p>{{data.value}}人</p>
			<span>{{data.name}}</span>
		</div>
		<!-- 底座背景 -->
		<!-- <div class="bgimg an"></div> -->
	</div>
</template>

<script>
	// import * as echarts from 'echarts'
	import 'echarts-gl'
	import {
		getPie3D,
		getParametricEquation
	} from '../chart3dcom.js' //工具类js，页面路径自己修改
	import apis from '@/api/api'
	// const color = ['#8651F4', '#BB42EF', '#6FCCE5', '#1D43F3']

	export default {
		name: 'chart',
		data() {
			return {
				optionData: [
					
				],
				data: {},
				statusChart: null,
				option: {},
				autoRotate: true,
				professionId:70,

			}
		},
		mounted() {
			this.$nextTick(function() {
				setTimeout(() => {
					this.initChart();
				}, 1000)
				// console.log(1)
			
			});
		
			//根据窗口变化自动调节图表大小
			const that = this
			window.onresize = function() {
				that.changeSize()
			}
			this.timer2 = setInterval(() => {
				
				this.getInternshipStu()
			}, 300000)
		},
		
		beforeDestroy() {
			//清除定时器
		
			clearInterval(this.timer2);
			this.timer2 = null;
			this.statusChart.clear();
		},
		created() {
			
			this.getInternshipStu()
		},
		methods: {
			getInternshipStu() {
				let data = {
					professionId: this.professionId,
					grade: 2019
				}
				apis.getInternshipStu(data).then(res => {
					// console.log(res.data)
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].value =Number(res.data[i].num) 
					}
					this.optionData=res.data
					this.optionData[0].itemStyle = {
						color: ' #0098FA',
					}
					this.optionData[1].itemStyle = {
						color: ' #46C4D0',
					}
					this.optionData[2].itemStyle = {
						color: ' #8895FA',
					}
					
					// this.joinData = res.data
					
				})
			},
			
			// 初始化label样式
			setLabel() {

			},
			// 图表初始化
			initChart() {
				let list = this.optionData
				let maxNum = Math.max.apply(Math, list.map(function(o) {
					return o.value
				}))
				let arr = list.filter((item) => {
					return item.value == maxNum
				})
				this.data = arr[0]
				// console.log(arr)
				this.statusChart = this.$echarts.init(this.$refs.chart)
				// 传入数据生成 option, 构建3d饼状图, 参数工具文件已经备注的很详细
				this.option = getPie3D(list, 0.8, 180, 30, 6, 0.7)
				this.statusChart.setOption(this.option)
				// 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
				this.option.series.push({
			
					name: 'pie2d', //自己根据场景修改
					backgroundColor: 'transparent',
					type: 'pie',
					startAngle: -30, // 起始角度，支持范围[0, 360]。
					clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
					radius: ['60%', '90%'],
					center: ['50%', '30%'],
					data: list,
					itemStyle: {
						opacity: 0 //这里必须是0，不然2d的图会覆盖在表面
					},
					// emphasis: {
					// 	label: {
					// 		show: true,
					// 		formatter: [
					// 			'{b|{b}}',
					// 			'{c|{c}}{b|}'
					// 		].join('\n'),
					// 		rich: {
					// 			b: {
					// 				color: '#fff',
					// 				lineHeight: 25,
					// 				align: 'left',
					// 				fontSize: 28,
					// 				fontWeight: 600,
					// 			},
					// 			c: {
					// 				fontSize: 18,
					// 				color: '#fff',
					// 				textShadowColor: '#fff',
			
					// 			},
			
					// 		}
					// 	}
					// },
			
					label: {
						show: false,
						position: 'center'
					},
					labelLine: {
						show: false,
					},
					legend: {
						show: true,
						data: list,
						
						bottom: 0,
						itemGap: 10,
						textStyle: {
							color: ' rgba(255, 255, 255, 0.64)',
							fontSize: 14
						},
						icon: 'circle',
						
					},
				})
				this.statusChart.setOption(this.option)
				this.bindListen(this.statusChart)
			},
			// 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
			// optionName是防止有多个图表进行定向option传递，单个图表可以不传，默认是opiton
			bindListen(myChart, optionName = 'option') {
				let selectedIndex = ''
				let hoveredIndex = ''
				// 监听点击事件，实现选中效果（单选）
				myChart.on('click', (params) => {
					// 从 option.series 中读取重新渲染扇形所需的参数，将是否选中取反。
					const isSelected = !this[optionName].series[params.seriesIndex].pieStatus
						.selected
					const isHovered =
						this[optionName].series[params.seriesIndex].pieStatus.hovered
					const k = this[optionName].series[params.seriesIndex].pieStatus.k
					const startRatio =
						this[optionName].series[params.seriesIndex].pieData.startRatio
					const endRatio =
						this[optionName].series[params.seriesIndex].pieData.endRatio
					// 如果之前选中过其他扇形，将其取消选中（对 option 更新）
					if (selectedIndex !== '' && selectedIndex !== params.seriesIndex) {
						this[optionName].series[
							selectedIndex
						].parametricEquation = getParametricEquation(
							this[optionName].series[selectedIndex].pieData.startRatio,
							this[optionName].series[selectedIndex].pieData.endRatio,
							false,
							false,
							k,
							this[optionName].series[selectedIndex].pieData.value
						)
						this[optionName].series[selectedIndex].pieStatus.selected = false
					}
					// 对当前点击的扇形，执行选中/取消选中操作（对 option 更新）
					this[optionName].series[
						params.seriesIndex
					].parametricEquation = getParametricEquation(
						startRatio,
						endRatio,
						isSelected,
						isHovered,
						k,
						this[optionName].series[params.seriesIndex].pieData.value
					)
					this[optionName].series[params.seriesIndex].pieStatus.selected = isSelected
					// 如果本次是选中操作，记录上次选中的扇形对应的系列号 seriesIndex
					selectedIndex = isSelected ? params.seriesIndex : null
					// 使用更新后的 option，渲染图表
					myChart.setOption(this[optionName])
				})
				// 监听 mouseover，近似实现高亮（放大）效果
				myChart.on('mouseover', (params) => {
					// console.log(params, 'params')
					// this.listArr = this.optionData
					// return
					if (params.seriesName == "pie2d") {
						this.data = params.data
					} else {
						if (params.name == "") {
							// this.data.name=params.seriesName
							// // let obj=this.listArr.find((item)=>{
							// // 	return item.name==this.data.name
							// // })
							// this.data.value =''
							// var name = ''
							// name = params.seriesId.split('\u0000')[1]
							// this.data.name = name
							// let obj=this.listArr.find((item)=>{
							// 	return item.name==this.data.name
							// })
							// console.log(obj)
			
							// for (let i = 0; i < this.listArr.length; i++) {
							// 	console.log(this.listArr, name)
							// 	if (this.listArr[i].name == name) {
			
							// 		this.data.value = this.listArr[i].value
							// 		console.log(this.data.value)
							// 	}
							// }
						} else {
							this.data.name = params.name
							this.data.value = params.percent
						}
			
					}
			
			
					// 准备重新渲染扇形所需的参数
					let isSelected
					let isHovered
					let startRatio
					let endRatio
					let k
					// 如果触发 mouseover 的扇形当前已高亮，则不做操作
					if (hoveredIndex === params.seriesIndex) {
						// 否则进行高亮及必要的取消高亮操作
					} else {
						// 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
						if (hoveredIndex !== '') {
							// 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
							isSelected = this[optionName].series[hoveredIndex].pieStatus.selected
							isHovered = false
							startRatio = this[optionName].series[hoveredIndex].pieData.startRatio
							endRatio = this[optionName].series[hoveredIndex].pieData.endRatio
							k = this[optionName].series[hoveredIndex].pieStatus.k
							// 对当前点击的扇形，执行取消高亮操作（对 option 更新）
							this[optionName].series[
								hoveredIndex
							].parametricEquation = getParametricEquation(
								startRatio,
								endRatio,
								isSelected,
								isHovered,
								k,
								this[optionName].series[hoveredIndex].pieData.value
							)
							this[optionName].series[hoveredIndex].pieStatus.hovered = isHovered
							// 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
							hoveredIndex = ''
						}
						// 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
						if (
							params.seriesName !== 'mouseoutSeries' &&
							params.seriesName !== 'pie2d'
						) {
							// 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
							isSelected =
								this[optionName].series[params.seriesIndex].pieStatus.selected
							isHovered = true
							startRatio =
								this[optionName].series[params.seriesIndex].pieData.startRatio
							endRatio = this[optionName].series[params.seriesIndex].pieData.endRatio
							k = this[optionName].series[params.seriesIndex].pieStatus.k
							// 对当前点击的扇形，执行高亮操作（对 option 更新）
							this[optionName].series[
								params.seriesIndex
							].parametricEquation = getParametricEquation(
								startRatio,
								endRatio,
								isSelected,
								isHovered,
								k,
								this[optionName].series[params.seriesIndex].pieData.value + 5 //高亮时 增加的高度
							)
							this[optionName].series[
								params.seriesIndex
							].pieStatus.hovered = isHovered
							// 记录上次高亮的扇形对应的系列号 seriesIndex
							hoveredIndex = params.seriesIndex
						}
						// 使用更新后的 option，渲染图表
						myChart.setOption(this[optionName])
					}
				})
				// 修正取消高亮失败的 bug
				myChart.on('globalout', () => {
					// 准备重新渲染扇形所需的参数
					let isSelected
					let isHovered
					let startRatio
					let endRatio
					let k
					if (hoveredIndex !== '') {
						// 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
						isSelected = this[optionName].series[hoveredIndex].pieStatus.selected
						isHovered = false
						k = this[optionName].series[hoveredIndex].pieStatus.k
						startRatio = this[optionName].series[hoveredIndex].pieData.startRatio
						endRatio = this[optionName].series[hoveredIndex].pieData.endRatio
						// 对当前点击的扇形，执行取消高亮操作（对 option 更新）
						this[optionName].series[
							hoveredIndex
						].parametricEquation = getParametricEquation(
							startRatio,
							endRatio,
							isSelected,
							isHovered,
							k,
							this[optionName].series[hoveredIndex].pieData.value
						)
						this[optionName].series[hoveredIndex].pieStatus.hovered = isHovered
						// 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
						hoveredIndex = ''
					}
					// 使用更新后的 option，渲染图表
					myChart.setOption(this[optionName])
				})
			},
			// 自适应宽高
			changeSize() {
				this.statusChart.resize()
			}
		}
	}
</script>

<style lang='scss' scoped>
	.chart-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
		align-items: center;
		position: relative;
	}

	.chartsGl {
		z-index: 1;
		margin-top: -100px;
	}

	.bgimg {
		background: no-repeat center;
		background-image: url("~@/assets/image3.png");
		background-size: 100% 100%;
		width: 90%;
		height: 70%;
		border-radius: 50%;

		position: absolute;
		transform: translate(0%, 10%);
		z-index: 0;
	}
	.title {
		position: absolute;
		top: 35%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		/* font-weight:600; */
		color: #fff;
	
		>p {
			font-size: 24px;
			font-weight: 600;
		}
	
		>span {
			font-size: 14px;
		}
	
	}
</style>