export default class {
	constructor(charts,echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		// console.log(seriesdata, 'seriesdata')
		let option = this.getOption(echarts, seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(echarts, seriesdata) {
		// function fontSize(res) {
		// 	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body
		// 		.clientWidth;
		// 	if (!clientWidth) return;
		// 	let fontSize = 100 * (clientWidth / 1920);
		// 	return res * fontSize;
		// }
		let option = {
			grid: {
				left: '25%',
				right: '12%',
				bottom: '0%',
				top: '5%',
				// containLabel: true
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'none'
				},
				formatter: '{b}：{c}人'
			},
			xAxis: {
				show: false,
				type: 'value'
			},
			// yAxis: [
			yAxis: {
				type: 'category',
				axisTick: {
					show: false,
					alignWithLabel: false,
					length: 5,
				},
				splitLine: {
					//网格线
					show: false,
				},
				inverse: 'true', //排序
				axisLine: {
					show: false,
					lineStyle: {
						color: '#fff',
					},
				},
				axisLabel: {
					fontSize: 14,
					// 取前六个字
					formatter: function(value) {
						if (value.length > 6) {
							return value.substring(0, 6) + '...';
						} else {
							return value;
						}
					},
				},
				data: seriesdata.xdata
			},
			series: [{

				type: 'bar',
				barWidth: 10,
				// barWidth: '15px', //条条宽度
				showBackground: false,
				backgroundStyle: {

				},
				label: {
					show: true,
					position: 'right', //每一条的数据位置
					color: '#FFFFFF',
					formatter: '{c}人',
					// color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
					// 	offset: 0,
					// 	color: 'rgba(27, 126, 242, 0)' // 0% 处的颜色
					// }, {
					// 	offset: 1,
					// 	color: '#1B7EF2' // 100% 处的颜色
					// }], false),
				},
				itemStyle: {

					normal: {
						color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
							offset: 0,
							color: 'rgba(27, 126, 242, 0)' // 0% 处的颜色
						}, {
							offset: 1,
							color: '#1B7EF2' // 100% 处的颜色
						}], false),
						// barBorderRadius: [20, 20, 20, 20], //圆角
						shadowBlur: 10,
						shadowColor: 'rgba(0, 103, 255, 0.2)',
						shadowOffsetX: -5,
						shadowOffsetY: 5,

					},

				},
				data: seriesdata.data
			}],
			dataZoom: [
				// 有滚动条
				{
					type: 'slider',
					yAxisIndex: 0,
					start: 0,
					end: 30,
					width: 10,
					right: 30,
					showDetail: false,
					showDataShadow: false,
					borderColor: 'transparent',
				},
			],
		};

		return option;
	}
}
