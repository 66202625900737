<template>
	<div class="screen">
		<div class="left">
			<div class="school">
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>校内课程</p>
				</div>
				<div style="width: 100%;height: 100%;" id="schoolIn"></div>
			</div>
			<div class="school">
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>校外课程</p>
				</div>
				<div style="width: 100%;height: 100%;" id="schoolOut"></div>
			</div>
			<div class="school">
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>活动展示</p>
				</div>
				<div style="width:100%" class="activeCon">
					<vue-seamless-scroll :data="activeList" :class-option="defaultOption"
						style="width:100%; height:100%;overflow: hidden;">
						<ul style="width:100%">
							<li v-for="(item, index) in activeList" :key="index" class="activeList">
								<span>{{item.type}}</span>
								<span>{{item.title}}</span>
								<span>{{item.time}}</span>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</div>
		</div>
		<div class="center">
			<div class="schoolInfos">
				<div class="schoolInfo">
					<span>
						{{form.professionName}}专业
					</span>
					<div>
						<span>总人数</span>
						<div>
							<div v-for="(item,index) in studentAmount" :key='index'>{{item}}</div>
						</div>
						<span>人</span>
					</div>
				</div>
			</div>

			<div class="attendance">
				<div>
					<div class="subtitle">
						<img src="../assets/titlebg.png" alt="">
						<span></span>
						<p>校内课程出勤率</p>
					</div>
					<div style="width: 100%;height: 100%;" id="attendanceIn"></div>
				</div>
				<div>
					<div class="subtitle">
						<img src="../assets/titlebg.png" alt="">
						<span></span>
						<p>校外课程出勤率</p>
					</div>
					<div style="width: 100%;height: 100%;" id="attendanceOut"></div>
				</div>
			</div>
			<div class="questionnaire">
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>教学工作调查问卷</p>
				</div>
				<div>
					<div style="width: 40%;height: 100%;" id="questionnaire"></div>
					<div style="width: 30%;height: 100%;" id="participation"></div>
					<div style="width: 30%;height: 100%;" id="averageScore"></div>
				</div>

			</div>
			<div class="competition">
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>大赛获奖</p>
				</div>
				<div style="width:100%" class="activeCon">
					<vue-seamless-scroll :data="competitionList" :class-option="defaultOption"
						style="width:100%; height:100%;overflow: hidden;">
						<ul style="width:100%">
							<li v-for="(item, index) in competitionList" :key="index" class="competitionList">
								<span>{{item.type}}</span>
								<span>{{item.title}}</span>
								<span>{{item.student}}</span>
								<span>{{item.level}}</span>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</div>
		</div>
		<div class="right">
			<div class='paper'>
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>论文进度（19级）</p>
				</div>
				<div style='height:90%'>
					<paper></paper>
				</div>

				<!-- <div>
					
					
					<div style="width: 100%;height: 90%;" id="paper"></div>
				</div> -->
				<!-- <div>
					<div>
						<div class='dot'></div>
						选题情况
					</div>
					<div style="width: 100%;height: 90%;" id="topic"></div>
				</div> -->

			</div>
			<div class='feedback'>
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>学生反馈</p>
				</div>
				<div style="width: 100%;height: 90%;" id="feedback"></div>
			</div>
		</div>
	</div>

</template>

<script>
	import drawMixin from "../utils/drawMixin";
	import schoolInEcharts from "@/components/echart/teaching/schoolIn.js";
	import schoolOutEcharts from "@/components/echart/teaching/schoolOut.js";
	import attendanceInEcharts from "@/components/echart/teaching/attendanceIn.js";
	import attendanceOutEcharts from "@/components/echart/teaching/attendanceOut.js";
	import questionnaireEcharts from "@/components/echart/teaching/questionnaire.js";
	import participationEcharts from "@/components/echart/teaching/participation.js";
	import averageScoreEcharts from "@/components/echart/teaching/averageScore.js";
	// import paperEcharts from "@/components/echart/teaching/paper.js";
	// import topicEcharts from "@/components/echart/teaching/topic.js";
	import paper from "@/components/echart/teaching/paper.vue";
	import feedbackEcharts from "@/components/echart/teaching/feedback.js";

	// import stuNmuEcharts from "@/components/echart/internship/stuNum.js";
	// import recommendEcharts from "@/components/echart/internship/recommend.js";
	// import joinNumEcharts from "@/components/echart/internship/joinNum.js";
	// import numEcharts from "@/components/echart/internship/num.js";
	// import clockEcharts from "@/components/echart/internship/clock.js";
	// import logEcharts from "@/components/echart/internship/log.js";
	// import reportEcharts from "@/components/echart/internship/report.js";
	// import evaluateEcharts from "@/components/echart/internship/evaluate.js";
	import apis from '@/api/api'
	// import 'echarts-wordcloud';

	// import Swiper from "@/components/echart/internship/swiper.vue"
	export default {
		components: {
			paper
		},
		mixins: [drawMixin],
		data() {
			return {
				schoolInData: {
					xdata: [],
					result: [
						[],
						[]
					]
				},
				schoolOutData: {
					xdata: [],
					result: [
						[],
						[],
						[]
					]
				},
				activeList: [{
						type: '专家讲座',
						title: '工科专业简历制作指导讲座',
						time: '2023-06-26'
					},
					{
						type: '院校汇报',
						title: '审计工作汇报',
						time: '2023-05-31'
					},
					{
						type: '班会',
						title: '校企合作实习认知班会',
						time: '2023-05-23'
					},
					{
						type: '院校汇报',
						title: '就业生就业率汇报',
						time: '2023-05-31'
					},
					{
						type: '其他活动',
						title: '护送学生前往实训基地',
						time: '2023-03-30'
					},
					{
						type: '班会',
						title: '就业培训班会',
						time: '2023-06-23'
					},
				],
				studentAmount: [1, 9, 9],
				attendanceData: {
					inList: {
						xdata: ['专业认知I', '中级BPO英语', '初级BPO英语', '外贸产品及客户认知'],
						data: [99, 100, 100, 100, ]
					},
					outList: {
						xdata: ['国贸综合实训', '就业指导课程', '跨境电商基础', 'ERP综合实训'],
						data: [100, 99, 97, 100, ]
					}
				},
				questionnaireData: {
					questionnaireList: [{
							value: 2,
							name: '讲师'
						},
						{
							value: 3,
							name: '辅导员'
						}
					]
				},
				participationData: 98.5,
				averageScoreData: 99.93,
				competitionList: [{
						type: '国家级',
						title: '山东省电子商务大赛',
						student: '姜珊珊、李海源、刘晓仪',
						level: '一等奖'
					},
					{
						type: '校级',
						title: '第九届“学创杯”全国大学生创业综合模拟大赛',
						student: '杨冰楠、范红欣、贾兴梅',
						level: '二等奖'
					},
					{
						type: '国家级',
						title: '山东省电子商务大赛',
						student: '冯振',
						level: '一等奖'
					},
					{
						type: '校级',
						title: '2022年第五届“远见者”杯简历设计大赛',
						student: '白海龙、孙萌、孙雨轩',
						level: '一等奖'
					},
					{
						type: '省级',
						title: '第十三届全国大学生电子商务“创新、创意及创业”挑战赛',
						student: '张朕、李姿琦',
						level: '三等奖'
					}
				],
				paperData: {
					xdata: ['选题', '任务书', '开题报告', '中期检查', '论文指导', '论文终稿'],
					list: [20, 40, 10, 80, 70, 90]
				},
				topicList: [{
						name: "驳回待修改",
						value: 20
					},
					{
						name: "待审批",
						value: 20
					},
					{
						name: "待提交",
						value: 20
					},
				],
				feedbackList: [{
						name: "课堂有趣",
						value: 20
					},
					{
						name: "传播正能量",
						value: 40
					},
					{
						name: "语言幽默风趣",
						value: 10
					},
					{
						name: "讲课积极",
						value: 80
					},
					{
						name: "讲师知识面广",
						value: 30
					},
					{
						name: "感兴趣",
						value: 10
					},
					{
						name: "课堂有趣",
						value: 20
					},
					{
						name: "传播正能量",
						value: 60
					},
					{
						name: "语言幽默风趣",
						value: 10
					},
					{
						name: "讲课积极",
						value: 80
					},
					{
						name: "讲师知识面广",
						value: 30
					},
					{
						name: "感兴趣",
						value: 70
					},
					{
						name: "课堂有趣",
						value: 20
					},
					{
						name: "传播正能量",
						value: 60
					},
					{
						name: "语言幽默风趣",
						value: 50
					},
					{
						name: "讲课积极",
						value: 30
					},
					{
						name: "讲师知识面广",
						value: 90
					},
					{
						name: "感兴趣",
						value: 30
					}
				],
				timer: null,
				timer2: null,
				timer3: null,
				stuForm: {},
				current: 1,
				stuTypeForm: {
					internship: null,
					school: null,
					job: null,
					other: null,
				},
				professionId: 70,
				schoolId: 64,
				grade: 2019,
				jobForm: {},
				// echarts
				schoolIns:null,
				schoolOuts:null,
				attendanceIns:null,
				attendanceOuts:null,
				questionnaires:null,
				participations:null,
				averageScores:null,
				feedbacks:null,

			}
		},
		props: {
			form: {
				type: Object
			},
		},
		computed: {
			defaultOption() {
				return {
					step: 0.4, // 数值越大速度滚动越快
					// limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
				}
			}
		},
		watch: {
			// joinData() {
			// 	this.joinNum()
			// },
			// 'recommendData.result'() {
			// 	this.recommend()
			// },
			// stuNumData() {
			// 	this.stuNum()
			// },
			// numData() {
			// 	this.num()
			// },
			'schoolInData.result'() {
				this.schoolIn()
			},
			'schoolOutData.result'() {
				this.schoolOut()
			},
			// logData() {
			// 	this.log()
			// },
			// reportData() {
			// 	this.report()
			// },
			// evaluateData() {
			// 	this.evaluate()
			// },
			// clockData() {

			// 	this.clock()
			// },

		},
		mounted() {
			// this.cancelLoading()
			// this.loading = false
			let that = this
			setTimeout(() => {
				that.schoolIn()
				that.schoolOut()
				that.attendanceIn()
				that.attendanceOut()
				that.questionnaire()
				that.participation()
				that.averageScore()
				// that.paper()
				// that.topic()
				that.feedback()
				// that.stuNum()
				// that.recommend()
				// that.joinNum()
				// that.num()
				// that.clock()
				// that.log()
				// that.report()
				// that.evaluate()
			}, 1000)
			// this.timer = setInterval(() => {
			// 	this.setNowTimes()
			// }, 1000)

			// this.timer2 = setInterval(() => {
			// 	this.getStudent()
			// 	this.getInSchool()
			// 	this.getOutSchool()
			// 	// this.getInternshipInfo()
			// 	// this.getClock()
			// 	// this.getGraduate()
			// }, 300000)
		},


		beforeDestroy() {
			this.schoolIns.dispose();
			this.schoolOuts.dispose();
			this.attendanceIns.dispose();
			this.attendanceOuts.dispose();
			this.questionnaires.dispose();
			this.participations.dispose();
			this.averageScores.dispose();
			this.feedbacks.dispose();
		},

		created() {
			this.professionId = this.$route.query.professionId
			this.schoolId = this.$route.query.schoolId
			this.grade = this.$route.query.grade
			// this.getInternshipAndSx()
			// this.getInternshipStu()
			// this.getStuType()
			// this.getRecommend()
			this.getStudent()
			this.getInSchool()
			this.getOutSchool()
			// this.getInternshipInfo()
			// this.getClock()
			// this.getGraduate()
		},

		methods: {
			getStudent() {
				let data = {
					professionId: this.professionId,

				}
				apis.getStudent(data).then(res => {
					this.studentAmount = []
					let num = 0
					for (let i = 0; i < res.data.length; i++) {
						num += res.data[i].readingStudent
					}
					this.studentAmount = num.toString().split('')
				})
			},
			getGraduate() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
				}
				apis.getGraduate(data).then(res => {
					this.jobForm = res.data
					this.studentAmount = res.data.studentAmount.toString().split('')
					// this.jobInfoData = [{
					// 	value: Number(res.data.jobAmount),
					// 	name: '已就业'
					// }, {
					// 	value:Number(res.data.jobSearchAmount),
					// 	name: '求职中'
					// }, {
					// 	value: Number(res.data.examineAmount),
					// 	name: '升学中'
					// }, {
					// 	value: Number(res.data.studyingAmount) + Number(res.data.internshipAmount),
					// 	name: '其他'
					// }]

				})
			},
			getInternshipAndSx() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
					// professionId: this.professionId,
					// condition: 0
				}
				apis.getInternshipAndSx(data).then(res => {
					let num1 = ((Number(res.data[0].实习) / Number(res.data[2].全部)) * 100).toFixed(1)
					let num2 = ((Number(res.data[1].实训) / Number(res.data[2].全部)) * 100).toFixed(1)
					this.numData = [num1, num2]
					console.log(num1, num2)

				})
			},
			getInternshipStu() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
				}
				apis.getInternshipStu(data).then(res => {
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].value = res.data[i].num
					}
					this.joinData = res.data
				})
			},
			getStuType() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
					// condition: 0
				}
				apis.getStuType(data).then(res => {
					// console.log(res)
					// this.stuTypeForm = res.data
					this.stuNumData[0].value = res.data[2]['实习']
					this.stuNumData[1].value = Number(res.data[3]['全部']) - Number(res.data[2]['实习']) - Number(res
						.data[0]['在校']) - Number(res.data[1]['实训'])
					this.stuNumData[2].value = res.data[0]['在校']
					this.stuNumData[3].value = res.data[1]['实训']
					this.stuTypeForm.internship = (Number(res.data[2]['实习']) / Number(res.data[3]['全部']) * 100)
						.toFixed(1)
					this.stuTypeForm.school = (Number(res.data[0]['在校']) / Number(res.data[3]['全部']) * 100)
						.toFixed(1)
					this.stuTypeForm.job = (Number(res.data[1]['实训']) / Number(res.data[3]['全部']) * 100).toFixed(1)
					this.stuTypeForm.other = (Number(this.stuNumData[1].value) / Number(res.data[3]['全部']) * 100)
						.toFixed(1)
					// this.stuTypeFormOtherNum=
				})
			},
			getRecommend() {
				let data = {
					professionId: this.professionId,
					// condition: 0
				}
				apis.getRecommend(data).then(res => {
					this.recommendData.xdata = []
					this.recommendData.result = []
					for (let i = 0; i < res.data.length; i++) {
						this.recommendData.xdata.push(res.data[i].datas)
						this.recommendData.result.push(res.data[i].num)
					}

				})
			},
			getInSchool() {
				let data = {
					professionId: this.professionId,
					// grade:2019
					// condition: 0
				}
				apis.getInSchool(data).then(res => {
					// console.log(res)
					this.schoolInData.xdata = []
					this.schoolInData.result[1] = []
					this.schoolInData.result[0] = []
					for (let i = 0; i < res.data.length; i++) {
						this.schoolInData.xdata.push(res.data[i].course_name)
						this.schoolInData.result[1].push(res.data[i].period)
						res.data[i].periodSum = Number(res.data[i].period) + Number(res.data[i].noPeriod)
						this.schoolInData.result[0].push(res.data[i].periodSum)
					}

				})
			},
			getOutSchool() {
				let data = {
					professionId: this.professionId,
					// condition: 0
				}
				apis.getOutSchool(data).then(res => {
					this.schoolOutData.xdata = []
					this.schoolOutData.result[1] = []
					this.schoolOutData.result[0] = []
					for (let i = 0; i < res.data.length; i++) {
						this.schoolOutData.xdata.push(res.data[i].course_name)
						this.schoolOutData.result[1].push(res.data[i].period)
						res.data[i].periodSum = Number(res.data[i].period) + Number(res.data[i].noPeriod)
						this.schoolOutData.result[0].push(res.data[i].periodSum)
						res.data[i].rate = (Number(res.data[i].period) / Number(res.data[i].periodSum) * 100)
							.toFixed(0)
						this.schoolOutData.result[2].push(res.data[i].rate)

					}

				})
			},
			getInternshipInfo() {
				let data = {
					professionId: this.professionId,
					// condition: 0
				}
				apis.getInternshipInfo(data).then(res => {
					// console.log(res)
					// this.
					this.logData = [
						[res.data[0].num],
						[res.data[1].num]
					]
					this.reportData = [
						[res.data[2].num],
						[res.data[3].num]
					]
					this.evaluateData = [
						[res.data[4].num],
						[res.data[5].num]
					]

				})
			},
			getClock() {
				let data = {
					professionId: this.professionId,
					// condition: 0
				}
				apis.getClock(data).then(res => {
					console.log(res)
					this.clockData = [Number(res.data[0].num), Number(res.data[1].num)]
					// this.clockData = [100, 70]


				})
			},

			schoolIn() {
				this.schoolIns = this.$echarts.init(document.getElementById("schoolIn")); //获取dom
				new schoolInEcharts(this.schoolIns, this.$echarts, this.schoolInData);
			},
			schoolOut() {
				this.schoolOuts = this.$echarts.init(document.getElementById("schoolOut")); //获取dom
				new schoolOutEcharts(this.schoolOuts, this.$echarts, this.schoolOutData);
			},
			attendanceIn() {
				this.attendanceIns = this.$echarts.init(document.getElementById("attendanceIn")); //获取dom
				new attendanceInEcharts(this.attendanceIns, this.$echarts, this.attendanceData.inList);
			},
			attendanceOut() {
				this.attendanceOuts = this.$echarts.init(document.getElementById("attendanceOut")); //获取dom
				new attendanceOutEcharts(this.attendanceOuts,this.$echarts, this.attendanceData.outList);
			},
			questionnaire() {
				this.questionnaires = this.$echarts.init(document.getElementById("questionnaire")); //获取dom
				new questionnaireEcharts(this.questionnaires,this.$echarts, this.questionnaireData.questionnaireList);
			},
			participation() {
				this.participations = this.$echarts.init(document.getElementById("participation")); //获取dom
				new participationEcharts(this.participations,this.$echarts, this.participationData);
			},
			averageScore() {
				this.averageScores = this.$echarts.init(document.getElementById("averageScore")); //获取dom
				new averageScoreEcharts(this.averageScores,this.$echarts, this.averageScoreData);
			},

			paper() {
				// new paperEcharts(this.$echarts, "paper", this.paperData);
			},
			topic() {
				// new topicEcharts(this.$echarts, "topic", this.topicList);
			},
			feedback() {
				this.feedbacks = this.$echarts.init(document.getElementById("feedback")); //获取dom
				new feedbackEcharts(this.feedbacks,this.$echarts, this.feedbackList);
			},

			// stuNum() {
			// 	new stuNmuEcharts(this.$echarts, "stuNum", this.stuNumData);
			// },
			// recommend() {
			// 	new recommendEcharts(this.$echarts, "recommend", this.recommendData);
			// },
			// joinNum() {
			// 	new joinNumEcharts(this.$echarts, "joinNum", this.joinData);
			// },
			// num() {
			// 	new numEcharts(this.$echarts, "num", this.numData);
			// },
			// clock() {
			// 	new clockEcharts(this.$echarts, "clock", this.clockData);
			// },
			// log() {
			// 	new logEcharts(this.$echarts, "log", this.logData);
			// },
			// report() {
			// 	new reportEcharts(this.$echarts, "report", this.reportData);
			// },
			// evaluate() {
			// 	new evaluateEcharts(this.$echarts, "evaluate", this.evaluateData);
			// },

		}
	}
</script>

<style lang="scss" scoped>
	.screen {
		width: 100%;
		height: calc(100% - 90px);
		display: flex;
		justify-content: space-between;
		background-image: url("../assets/sxBg.png");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center certer;
		overflow: hidden;

		>.left {
			width: 27%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			>.school {
				width: 100%;
				height: 32%;
				padding: 20px;
				overflow: hidden;



				.activeList {
					width: 100%;
					display: flex;
					justify-content: space-between;
					padding: 10px 0;

					>span:nth-of-type(1) {
						color: #85CCFF;
						font-size: 16px;
						width: 20%;
					}

					>span:nth-of-type(2) {
						color: rgba(255, 255, 255, 0.8);
						font-size: 16px;
						display: block;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						-o-text-overflow: ellipsis;
						width: 60%;
					}

					>span:nth-of-type(3) {
						color: #86909C;
						font-size: 16px;
						width: 20%;
						display: block;
						text-align: right;
					}
				}
			}
		}

		>.center {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 45%;
			height: 100%;

			>.schoolInfos {
				padding: 0 20px;
				height: 10%;
				width: 100%;

				>.schoolInfo {
					height: 100%;
					width: 100%;
					text-align: center;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0 30px;
					background-image: url("../assets/border1.png");
					background-size: 100% 100%;
					background-position: center center;

					>span {
						display: block;
						font-size: 20px;
						font-weight: 550;
						background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}

					>div {
						display: flex;
						align-items: flex-end;
						justify-content: center;

						>span {
							font-weight: 500;
							font-size: 20px;
							color: #fff;
							display: block;
						}

						>div {
							display: flex;

							>div {
								width: 46px;
								height: 46px;
								background-image: url("../assets/numbg.png");
								background-size: 100% 100%;
								background-position: center center;
								margin: 0 5px 0 5px;
								color: #00FFF3;
								line-height: 46px;
								text-align: center;
								font-size: 30px;
							}
						}
					}

				}
			}


			>.attendance {
				width: 100%;
				height: 28%;
				padding: 20px;
				display: flex;
				justify-content: space-between;

				>div {
					width: 48%;
				}
			}

			>.questionnaire {
				width: 100%;
				height: 28%;
				padding: 20px;

				>div:nth-of-type(2) {
					width: 100%;
					height: 90%;
					display: flex;
					justify-content: space-between;
				}
			}

			>.competition {
				width: 100%;
				height: 32%;
				padding: 20px;

				.competitionList {
					width: 100%;
					display: flex;
					justify-content: space-between;
					padding: 10px 0;

					>span:nth-of-type(1) {
						color: #D9B75F;
						font-size: 16px;
						width: 10%;
					}

					>span:nth-of-type(2) {
						color: rgba(255, 255, 255, 0.8);
						font-size: 16px;
						display: block;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						-o-text-overflow: ellipsis;
						width: 40%;
					}

					>span:nth-of-type(3) {
						color: #85CCFF;
						font-size: 16px;
						width: 40%;
						display: block;
						text-align: right;
					}

					>span:nth-of-type(4) {
						color: #D9B75F;
						font-size: 16px;
						width: 10%;
						display: block;
						text-align: right;
					}
				}
			}

		}

		>.right {
			width: 27%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			>.paper {
				width: 100%;
				height: 64%;
				padding: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				// >div {
				// 	width: 100%;
				// 	height: 49%;

				// }

				// >div:nth-of-type(2) {
				// 		height: 49%;
				// 	>div:nth-of-type(1) {
				// 		display: flex;
				// 		align-items: center;
				// 		font-size: 14px;
				// 		font-weight: 500;
				// 		background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
				// 		-webkit-background-clip: text;
				// 		-webkit-text-fill-color: transparent;
				// 		background-clip: text;
				// 		text-fill-color: transparent;
				// 	}
				// }

				// .dot {
				// 	width: 5px;
				// 	height: 5px;
				// 	border-radius: 50%;
				// 	background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
				// 	margin-right: 5px;
				// }

				// >div:nth-of-type()
			}

			>.feedback {
				width: 100%;
				height: 35%;
				padding: 20px;
			}

		}
	}

	.activeCon {
		overflow: hidden;
	}

	.activeCon ::v-deep div {
		width: 100% !important;
	}

	.subtitle {
		width: 100%;
		height: 33px;
		display: flex;
		position: relative;
		align-items: center;

		>p {
			font-size: 15px;
			font-weight: bold;
			height: 33px;
			background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
			background-clip: text;
			text-fill-color: transparent;
			line-height: 33px;
			color: transparent;
		}

		>div {
			width: 100%;
			display: flex;
			justify-content: space-between;

			>p {
				font-size: 15px;
				font-weight: bold;
				height: 33px;
				background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
				background-clip: text;
				text-fill-color: transparent;
				line-height: 33px;
				color: transparent;
			}
		}

		>span {
			display: block;
			width: 3.85px;
			height: 15.41px;
			background: #3FB2F9;
			margin-right: 10px;
			border-radius: 30px;
		}

		>img {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
		}
	}

	.subtitle2 {
		background: #0098FA;
		width: 84px;
		padding: 0 10px;
		// box-sizing: border-box;
		height: 25px;
		text-align: center;
		line-height: 25px;
		border-radius: 8px 0px 8px 0px;
		font-size: 12px;
	}

	::v-deep .el-input__inner {
		background: #0A469E !important;
		border: 0.91px solid #0A469E !important;
		color: #fff !important;
	}

	.bgimg2 {
		background: no-repeat center;
		background-image: url("~@/assets/sxcenBg2.png");
		background-size: 100% 100%;
		background-position: center bottom;
		width: 60%;
		height: 60%;
		position: absolute;
		top: -20px;
		z-index: 2;
		// margin-top:40px;
	}

	.bgimg {
		background: no-repeat center;
		background-image: url("~@/assets/sxcenBg.png");
		background-size: 100% 100%;
		background-position: center bottom;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 0;
		margin-top: 40px;
	}

	.stuStatus {
		position: absolute;
		z-index: 3;
		width: 100%;
		display: flex;
		justify-content: space-around;
		height: 350px;
		padding-left: 50px;
		// align-items: center;
		// position: relative;
		// transition: 5s linear;
		// transform: rotateY(360deg);

		/*过渡效果*/
		.stuStatusCon {
			text-align: center;
			// position: absolute;
			left: 0;
			right: 0;
			width: 155px;
			height: 200px;
			padding-top: 50px;

			>span:nth-of-type(1) {
				font-size: 18px;
			}

			>span:nth-of-type(2) {
				font-size: 14px;
				display: block;
			}

			>p {
				font-size: 24px;
			}

			>div {
				width: 155px;
				height: 89px;
				background-image: url("~@/assets/icon10.png");
				background-size: 100% 100%;
				background-position: center center;
			}
		}
	}

	.el-carousel {
		width: 100% !important;
	}

	::v-deep .el-carousel__container {
		// padding-top:30px;
	}

	::v-deep .el-carousel__mask {
		background: transparent !important;
	}

	::v-deep .el-carousel__arrow {
		background: transparent !important;
	}

	::v-deep .el-carousel__item--card {
		width: 25% !important;
	}

	.carousel-3d-slide {
		background: none;
		border: 0px;
	}
</style>
<style type="text/css">
	.el-select-dropdown {
		background: #0A469E !important;
		color: #fff !important;
		border: 0.91px solid #0A469E !important;
		font-size: 12px !important;
		/* height: 20px !important;
		line-height: 20px !important; */

	}

	.el-select-dropdown__item {
		color: #fff !important;
		font-size: 12px !important;
		background: #0A469E !important;
	}

	.el-select-dropdown__item.hover {
		color: #fff !important;
		background: #0A469E !important;
		font-size: 12px !important;

	}

	/* .tab-con>div {
		display: flex;
		width: 100% !important;

	} */
</style>