export default class {
	constructor(charts,echarts, seriesdata) {
		// console.log(seriesdata) //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(echarts, seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});

	}
	getOption(echarts, seriesdata) {
		let option = {
			title: [{
					text: "{a|" + seriesdata + "%}",
					show: true,
					x: "center",
					y: "40%",
					textStyle: {
						rich: {
							a: {
								fontSize: 20,
								color: "#FFFFFF",
								fontWeight: "bold",
							},
						},
					},
				},
				{
					text: '学生参与度',
					x: "center",
					y: "55%",
					borderColor: "#fff",
					textStyle: {
						fontWeight: "normal",
						fontSize: 16,
						color: "#fff",
					},
				},
			],
			polar: {
				radius: ['50%', '70%'],
				center: ["50%", "50%"],
			},
			angleAxis: {
				max: 100,
				show: false,
			},
			radiusAxis: {
				type: "category",
				show: true,
				axisLabel: {
					show: false,
				},
				axisLine: {
					show: false,
				},
				axisTick: {
					show: false,
				},
			},
			series: [{
				data: [seriesdata],
				name: "",
				type: "bar",
				roundCap: true,
				showBackground: true,
				backgroundStyle: {
					color: "rgba(19, 84, 146, .4)",
				},
				coordinateSystem: "polar",
				itemStyle: {
					normal: {
						color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
								offset: 0,
								color: "#005DCF",
							},
							{
								offset: 1,
								color: "#00CCFF",
							},
						]),
					},
				},
			}, ],
		};

		return option;
	}
}