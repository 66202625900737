export default class {
	constructor(charts,echarts, xdata, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(this.echarts, xdata, seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(echarts, xdata, seriesdata) {
		// function fontSize(res) {
		// 	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body
		// 		.clientWidth;
		// 	if (!clientWidth) return;
		// 	let fontSize = 100 * (clientWidth / 1920);
		// 	return res * fontSize;
		// }
		let option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'none',
				},
			},

			grid: {
				top: "0%",
				right: "10%",
				bottom: "0%",
				left: "5%",
				containLabel: true
			},
			xAxis: {
				show: false
			},
			yAxis: {
				type: 'category',
				data: xdata,
				splitLine: { //决定是否显示坐标中网格
					show: false,
				},
				axisLine: {
					lineStyle: {
						fontSize: 14,
						color: 'rgba(255,255,255,0)',
					}
				}, //左线色
				axisLabel: {
					textStyle: {
						color: '#fff',
						fontSize: 14,
					},
					formatter: '{value}'
				},
			},
			series: [{
				name: '',
				type: 'bar',
				barWidth: 15,
				tooltip: {
					valueFormatter: function(value) {
						return value;
					}
				},
				itemStyle: {
					normal: {
						//渐变色
						color: function(colors) {
							var colorList = [
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "#FF222B"
									},
									{
										offset: 1,
										color: "#FFFFFF"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(39, 97, 239, 1)"
									},
									{
										offset: 1,
										color: "rgba(255, 255, 255, 0.71)"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(209, 228, 0, 1)"
									},
									{
										offset: 1,
										color: "rgba(255, 255, 255, 1)"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(36, 219, 97, 1)"
									},
									{
										offset: 1,
										color: "rgba(255, 255, 255, 1)"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(255, 255, 255, 1)"
									},
									{
										offset: 1,
										color: "rgba(213, 254, 255, 1)"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(253, 59, 126, 1)"
									},
									{
										offset: 1,
										color: "rgba(255, 255, 255, 1)"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(255, 167, 34, 1)"
									},
									{
										offset: 1,
										color: "rgba(255, 255, 255, 1)"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(74, 93, 244, 1)"
									},
									{
										offset: 1,
										color: "rgba(255, 255, 255, 1)"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(172, 45, 246, 1)"
									},
									{
										offset: 1,
										color: "rgba(255, 255, 255, 1)"
									}
								]),
								new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
										offset: 0,
										color: "rgba(255, 124, 31, 1)"
									},
									{
										offset: 1,
										color: "rgba(255, 255, 255, 1)"
									}
								]),
							];
							return colorList[colors.dataIndex];
						},
						barBorderRadius: [0, 10, 10, 0],
						label: {
							show: true, //开启显示
							position: 'right', //在上方显示
							textStyle: { //数值样式
								color: '#fff',
								fontSize: 14
							},
							formatter: function(val) {
								return val.value + '人'
							},


						}
					}
				},
				data: seriesdata
			}, ]
		};

		return option;
	}
}