export default class {
	constructor(charts, echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		// console.log(seriesdata, 'seriesdata')
		let option = this.getOption(echarts, seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(echarts, seriesdata) {
		let option = {
			series: [{
				type: 'wordCloud',
				gridSize: 7,
				sizeRange: [15, 40],
				rotationRange: [-90, 90],
				// rotationStep: 45,
				shape: 'circle',
				width: '100%',
				height: '90%',
				drawOutOfBound: false,
				textStyle: {
					normal: {
						color: function() {
							return (
								'rgb(' + [
									Math.round(Math.random() * 500),
									Math.round(Math.random() * 300),
									Math.round(Math.random() * 200)
								].join(',') +
								')'
							)
						}
					},
					emphasis: {
						shadowBlur: 10,
						shadowColor: '#ffffff'
					}
				},
				data: seriesdata
			}]
		};

		return option;
	}
}