<template>
	<div style="height: 100%;" class="paperCon">
		<div style="width: 100%;height: 49%;" id="paper"></div>
		<div style="height: 49%;">
			<div>
				<div class='dot'></div>
				{{title}}情况
			</div>
			<div style="width: 100%;height: 90%;" id="topic"></div>
		</div>
	</div>
</template>

<script>
	import {
		autoHover
	} from '../tooltip.js'
	import * as echarts from 'echarts';
	export default {
		data() {
			return {
				timer2: null,
				timer3: null,
				paperData: {
					xdata: ['选题', '任务书', '开题报告', '中期检查', '论文指导', '论文终稿'],
					list: [20, 40, 10, 80, 70, 90]
				},
				title: '选题',
				topicList: [{
						name: "驳回待修改",
						value: 20
					},
					{
						name: "待审批",
						value: 20
					},
					{
						name: "待提交",
						value: 20
					},
				],
				topicChart: null,
				myChart: null,
			}
		},
		watch: {
			title(val) {
				this.title = val
				// console.log(this.title)
			}
		},
		mounted() {
			this.$nextTick(function() {
				setTimeout(() => {
					this.initChart();
					this.topic()
					console.log(1)
				}, 1000)
			});

			//根据窗口变化自动调节图表大小
			// const that = this
			// window.onresize = function() {
			// 	that.changeSize()
			// }
			this.timer2 = setInterval(() => {

				// this.getInternshipStu()
			}, 300000)
		},

		beforeDestroy() {
			//清除定时器

			clearInterval(this.timer2);
			this.timer2 = null;
			clearTimeout(this.timer3)
			this.timer3 = null;
			console.log('beforeDestroy')
			// let topicChart = echarts.init(document.getElementById("topic"));
			this.myChart.dispose();
			this.myChart.clear()
			this.topicChart.dispose();
			this.topicChart.clear()

		},
		// destroyed() {
		// 	let topicChart = echarts.init(document.getElementById("topic"));
		// 	topicChart.dispose();
		// 	topicChart.clear()
		// 	console.log('destroyed')
		// },
		created() {

			// this.getInternshipStu()
		},
		methods: {
			initChart() {
				let that = this
				that.myChart = echarts.init(document.getElementById("paper"));
				that.myChart.setOption({
					animationDuration: 2800, //加上这个
					animationEasing: "quadraticOut", //加上这个

					tooltip: {
						axisPointer: {
							type: 'shadow'
						},
						position: 'top',
						trigger: 'axis',
						borderColor: '#28F1FA',
						backgroundColor: 'rgba(0, 13, 58, 0.35)',
						textStyle: {
							color: 'white', //设置文字颜色
						},
						borderWidth: 1,
						padding: 5,
						className: 'custom-tooltip',
					},
					grid: {
						top: '5%',
						right: "1%",
						bottom: "3%",
						left: "1%",
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: this.paperData.xdata,
						axisPointer: {
							type: 'shadow'
						},
						axisLine: {
							lineStyle: {
								color: "rgba(78, 202, 217, 0.4)"
							}
						},
						axisLabel: {
							interval: 0,
							textStyle: {
								fontSize: 14,
								color: '#fff',
							},
						},
					}],
					yAxis: [{
						type: 'value',
						name: '单位（人）',
						// min: 0,
						// max: 250,
						// interval: 50,
						axisLabel: {
							textStyle: {
								color: '#fff',
								fontSize: 14,
							},
							formatter: '{value} '
						},
						axisLine: {
							show: true,
							lineStyle: {
								fontSize: 14,
								color: 'rgba(255,255,255,0)',
							}

						}, //左线色
						splitLine: { //决定是否显示坐标中网格
							// show: false,
							lineStyle: {
								color: ['rgba(255,255,255,0.5)'],
								type: 'dot'
							}
						},
					}, ],
					series: [{
						// name: '采购项目提报',
						type: 'bar',
						barWidth: 20,
						zlevel: 2,
						itemStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#01B1FF'
								}, {
									offset: 1,
									color: '#033BFF'
								}], false)
							}
						},
						label: {
							normal: {
								show: true,
								fontSize: 14,
								color: '#fff',
								position: 'top',
							}
						},
						data: this.paperData.list
					}]
				})
				autoHover(that.myChart, that.paperData.list, 5000)

				// 高亮事件
				that.myChart.on('highlight', function(params) {
					// let title=''
					if (params.dataIndex != undefined) {
						let index = params.dataIndex
						that.$nextTick(function() {
							setTimeout(() => {
								that.change(index)
							}, 500)

						})

					}



				})
			},
			change(index) {
				this.topicList = []
				if (index == 0) {
					this.title = '选题'
					this.topicList = [{
							name: "驳回待修改",
							value: 20
						},
						{
							name: "待审批",
							value: 20
						},
						{
							name: "待提交",
							value: 20
						},
					]
				} else if (index == 1) {
					this.title = '任务书'
					this.topicList = [{
							name: "驳回待修改",
							value: 10
						},
						{
							name: "待审批",
							value: 70
						},
						{
							name: "待提交",
							value: 40
						},
					]
				} else if (index == 2) {
					this.title = '开题报告'
					this.topicList = [{
							name: "驳回待修改",
							value: 10
						},
						{
							name: "待审批",
							value: 20
						},
						{
							name: "待提交",
							value: 30
						},
					]
				} else if (index == 3) {
					this.title = '中期检查'
					this.topicList = [{
							name: "驳回待修改",
							value: 10
						},
						{
							name: "待审批",
							value: 90
						},
						{
							name: "待提交",
							value: 50
						},
					]
				} else if (index == 4) {
					this.title = '论文指导'
					this.topicList = [{
							name: "驳回待修改",
							value: 50
						},
						{
							name: "待审批",
							value: 30
						},
						{
							name: "待提交",
							value: 90
						},
					]
				} else if (index == 5) {
					this.title = '论文终稿'
					this.topicList = [{
							name: "驳回待修改",
							value: 20
						},
						{
							name: "待审批",
							value: 10
						},
						{
							name: "待提交",
							value: 20
						},
					]

				}
				this.topic()
			},
			topic() {
				console.log(2)
				this.topicChart = echarts.init(document.getElementById("topic"));
				this.topicChart.setOption({
					color: [
						'#F67FA8',
						'#9BEF59',
						'#f4d64e',
						'#0072ff'
					],
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						left: 'right',
						top: '10%',
						icon: "circle",
						textStyle: {
							color: "#fff",
							fontSize: 14,
						},
					},
					series: [{
						name: '选题情况',
						type: 'pie',
						radius: '90%',
						center: '50%',
						label: {
							show: true,
							position: 'inside',
							textStyle: {
								fontSize: '14',
								color: '#FFFFFF',
							},
							formatter: '{d}%',
							rich: {
								fonts: {
									color: '#FFFFFF',
									fontSize: '14'
								}
							}
						},
						data: this.topicList,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				})
				window.addEventListener("resize", function() {
					this.topicChart.resize();
				});

			},
		},
	}
</script>

<style lang="scss" scoped>
	.paperCon {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		>div:nth-of-type(2) {
			height: 49%;

			>div:nth-of-type(1) {
				display: flex;
				align-items: center;
				font-size: 14px;
				font-weight: 550;
				background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
			}
		}
	}

	.dot {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
		margin-right: 5px;
	}
</style>