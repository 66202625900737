<template>
	<div class="screen">
		<div class="left">
			<div class="leftTop">
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>教学师资科研</p>
				</div>
				<div class="teaching">
					<div class="subtitle">
						<span></span>
						<p>师资培养</p>
					</div>
					<div class="teachingCon">
						<div>
							<span>专题讲座</span>
							<p><span>{{trainingForm.project2Num||0}}</span>次</p>
						</div>
						<div>
							<span>师资培训</span>
							<p><span>{{trainingForm.project1Num||0}}</span>次 <span style='color:#129BFF;font-size:14px'>
									/{{trainingForm.people1Num||0}}人次
								</span> </p>
						</div>
						<div>
							<span>企业挂职锻炼</span>
							<p><span>{{trainingForm.project3Num||0}}</span>次 <span style='color:#129BFF;font-size:14px'>
									/{{trainingForm.people3Num||0}}人次
								</span> </p>
						</div>
					</div>
				</div>
				<div class="teaching">
					<div class="subtitle">
						<span></span>
						<p>教学资源开发</p>
					</div>
					<div class="teachingCon">
						<div>
							<span>开发课程</span>
							<p><span>{{resourceForm.project1Num||0}}</span>门</p>
						</div>
						<div>
							<span>开发教材</span>
							<p><span>{{resourceForm.project2Num||0}}</span>门</p>
						</div>
						<div>
							<span>开发网上资源</span>
							<p><span>{{resourceForm.project3Num||0}}</span>门</p>
						</div>
					</div>
				</div>
				<div class="teaching">
					<div class="subtitle">
						<span></span>
						<p>科研</p>
					</div>
					<div class="teachingCon">
						<div>
							<span>共同承担科研项目</span>
							<p><span>{{scientificForm.project1Num||0}}</span>项</p>
						</div>
						<div>
							<span>共同承担横向课题</span>
							<p><span>{{scientificForm.project2Num||0}}</span>项</p>
						</div>

					</div>
				</div>

			</div>
			<div class="leftBot">
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>师资配备</p>
				</div>
				<techerType></techerType>
			</div>
		</div>
		<div class="center">
			<div class='school'>{{form.professionName}}</div>
			<div class="status">

				<div class="subtitle subtitleCen">
					<!-- <img src="../assets/titlebg.png" alt=""> -->
					<span></span>
					<p>招生数据</p>
				</div>

				<div class="bgimg an"></div>
				<div class="stunum">
					<span>学生数量</span>
					<div>
						<div v-for="(item,index) in studentAmount" :key='index'>{{item}}</div>
					</div>
				</div>
				<div class="stuCon">
					<div :class="current==1?'current1':''" v-if="stuData[0]!=undefined">
						<div>
							<span style="font-size: 14px;">实际:{{stuData[0].readingStudent}}人/</span>
							<span style="font-size: 12px;">计划:{{stuData[0].initStudent}}人</span>
						</div>
						<p>{{stuData[0].grade}}级</p>
					</div>
					<div :class="current==2?'current2':''" v-if="stuData[1]!=undefined">
						<p>{{stuData[1].grade}}级</p>
						<div>
							<span style="font-size: 14px;">实际:{{stuData[1].readingStudent}}人/</span>
							<span style="font-size: 12px;">计划:{{stuData[1].initStudent}}人</span>

						</div>

					</div>
					<div :class="current==3?'current3':''" v-if="stuData[2]!=undefined">
						<p>{{stuData[2].grade}}级</p>
						<div>
							<span style="font-size: 14px;">实际{{stuData[2].readingStudent}}人/</span>
							<span style="font-size: 12px;">计划:{{stuData[2].initStudent}}人</span>
						</div>

					</div>

					<div :class="current==4?'current4':''" v-if="stuData[3]!=undefined">
						<p>{{stuData[3].grade}}级</p>
						<div>
							<span style="font-size: 14px;">实际:{{stuData[3].readingStudent}}人/</span>
							<span style="font-size: 12px;">计划:{{stuData[3].initStudent}}人</span>
						</div>

					</div>
					<div :class="current==5?'current5':''" v-if="stuData[4]!=undefined">

						<div>
							<span style="font-size: 14px;">实际:{{stuData[4].readingStudent}}人/</span>
							<span style="font-size: 12px;">计划:{{stuData[4].initStudent}}人</span>
						</div>
						<p>{{stuData[4].grade}}级</p>
					</div>


				</div>
			</div>
			<div class="cenbottom">
				<div class='rate'>
					<div>
						<img src="../assets/zj.png" alt="">
						<div>
							<span>资金支持</span>
							<p>
								<span>{{assetForm.oneAsset==0?'**':assetForm.oneAsset|| '**'}}</span>
								万
							</p>
						</div>
					</div>
					<div>
						<img src="../assets/sys.png" alt="">
						<div>
							<span>实验室建设</span>
							<p>
								<span>{{assetForm.twoAsset||0}}</span>
								万
							</p>
						</div>
					</div>
					<div>
						<img src="../assets/pt.png" alt="">
						<div>
							<span>软件平台投入</span>
							<p>
								<span>{{assetForm.threeAsset||0}}</span>
								万
							</p>
						</div>
					</div>
				</div>

			</div>
			<div class="stuImg">
				<div>
					<div class="subtitle">
						<img src="../assets/titlebg.png" alt="">
						<span></span>
						<p>毕业级就业状况</p>
					</div>
					<div style="width: 100%;height: 90%;" id="jobInfoEcharts"></div>
				</div>
				<div>
					<div class="subtitle">
						<img src="../assets/titlebg.png" alt="">
						<span></span>
						<p>优秀毕业生</p>
					</div>
					<el-carousel height="250px" indicator-position='none'>
						<el-carousel-item v-for="(item,index) in tableList" :key="index">
							<div class="stu">
								<div>
									<img :src="item.avatar" alt="">
								</div>
								<div>
									<p>{{item.name}}</p>
									<div>{{item.schoolName}}</div>
									<div>{{item.secondSchoolName}}</div>
									<div>{{item.professionName}}</div>
									<div>{{item.className}}</div>
								</div>
							</div>

						</el-carousel-item>
					</el-carousel>
				</div>
			</div>

			<!-- </div> -->
		</div>
		<div class="right">

			<div class='certificate'>
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>技能证书</p>
				</div>
				<div style="width: 100%;height: 90%;" id="certificateEcharts"></div>
			</div>
			<div class='competition'>
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>技能大赛</p>
				</div>
				<competition></competition>

			</div>
			<div class='stuInfo'>
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>企业职位</p>
				</div>
				<div class="companyJob">
					<div>
						<p>企业数量</p>
						<p>{{companyForm.companyAmount}} <span>个</span> </p>
					</div>
					<div>
						<p>职位数量</p>
						<p>{{companyForm.jobAmount}} <span>个</span> </p>
					</div>
				</div>
			</div>

		</div>
	</div>

</template>

<script>
	import drawMixin from "../utils/drawMixin";
	import stuNumEcharts from "@/components/echart/index/stuNum2.js";
	import certificateEcharts from "@/components/echart/index/certificate.js";
	import jobInfoEcharts from "@/components/echart/index/jobInfo.js";
	import internshipEcharts from "@/components/echart/index/internship.js";
	import techerType from '@/components/echart/index/techerType.vue'
	import competition from '@/components/echart/index/competition.vue'
	import apis from '@/api/api'
	export default {
		components: {
			// swiper,
			techerType,
			competition
		},
		mixins: [drawMixin],
		data() {
			return {
				stuData: {
					xdata: [],
					// result: {}
					// 	[],
					// 	[],
					// 	[]
					// ],
					data1: [],
					data2: []
				},
				jobInfoData: [],
				tableList: [],
				certificateData: {
					xdata: [],
					data: []
				},
				certificateData2: [],
				internshipData: 0,
				internshipNum: [],
				statusList: [],
				professionId: 70,
				schoolId: 64,
				grade: 2019,
				condition: 1,
				pushForm: {},
				studentAmount: [],
				timer: null,
				timer2: null,
				timer3: null,
				stuForm: {},
				current: 1,
				resourceForm: {},
				trainingForm: {},
				scientificForm: {},
				sum: 0,
				assetForm: {

				},
				companyForm: {},
				jobData: {
					xdata: [],
					result: [
						[],
						[],
						[]
					]
				},
				stuNums: null,
				certificates: null,
				jobInfos: null,
				internships: null,
			}
		},
		props: {
			form: {
				type: Object
			},
		},
		computed: {
			defaultOption() {
				return {
					direction: 2,
					step: 1, // 数值越大速度滚动越快
					limitMoveNum: 1, // 开启无缝滚动的数据量 设置(数值<=页面展示数据条数不滚)(超过页面展示条数滚动)
					openWatch: true, // 开启数据实时监控刷新dom
					singleWidth: 93,
					waitTime: 2000 // 单行停顿时间(singleHeight,waitTime)

				}
			}
		},
		watch: {
			certificateData2() {
				this.certificate()
			},
			'jobData.xdata'() {
				this.jobInfo()
			}

		},
		mounted() {
			// this.cancelLoading()
			// this.loading = false
			let that = this
			setTimeout(() => {
				that.jobInfo()
				// that.internship()
				that.certificate()
			}, 1000)
			setTimeout(() => {
				// that.stuNum()
			}, 2000)
			// this.timer2 = setInterval(() => {
			// 	this.getResource()
			// 	this.getTraining()
			// 	this.getScientific()
			// 	this.getCertificate()
			// 	this.getStudent()
			// 	this.getGraduate()
			// 	this.getOutList()
			// 	this.getAssetCount()
			// 	this.getCompanyNum()
			// 	this.getEmployment()
			// }, 300000)

			this.timer3 = setInterval(() => {
				let data = JSON.parse(localStorage.getItem('stuData'))
				// console.log(data,'11111111')
				if (this.current == data.list.length) {
					this.current = 1
					let num = data.list[0].readingStudent
					this.studentAmount = String(num).split('')
				} else {
					this.current = this.current + 1
					let num = data.list[this.current - 1].readingStudent
					this.studentAmount = String(num).split('')
				}
			}, 1500)
		},


		beforeDestroy() {
			//清除定时器

			// clearInterval(this.timer2);
			// this.timer2 = null;
			clearInterval(this.timer3);
			this.timer3 = null
			localStorage.removeItem('stuData')
			// this.stuNums.clear();
			this.certificates.dispose();
			this.jobInfos.dispose();
			// this.internships.clear();
		},


		created() {
			this.professionId = this.$route.query.professionId
			this.schoolId = this.$route.query.schoolId
			this.grade = this.$route.query.grade
			this.getResource()
			this.getTraining()
			this.getScientific()
			this.getCertificate()
			this.getStudent()
			// this.getCooperation()
			// this.getInternshipNum()
			this.getGraduate()
			this.getOutList()
			this.getAssetCount()
			this.getCompanyNum()
			this.getEmployment()
		},

		methods: {
			getEmployment() {
				let data = {
					professionId: this.professionId,
				}
				apis.getEmployment(data).then(res => {
					res.data = res.data.reverse()
					// console.log(res)
					this.jobData.xdata = []
					this.jobData.result[0] = []
					this.jobData.result[1] = []
					this.jobData.result[2] = []
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].rate = (Number(res.data[i].jobRate * 100)).toFixed(0)
						this.jobData.xdata.push(res.data[i].grade)
						this.jobData.result[0].push(res.data[i].studentAmount)
						this.jobData.result[1].push(res.data[i].jobAmount)
						this.jobData.result[2].push(res.data[i].rate)
					}
					// this.companyForm = res.data
				})
			},
			getCompanyNum() {
				apis.getCompanyNum().then(res => {
					if(res.data!=undefined){
						this.companyForm = res.data
					}

				})
			},
			getAssetCount() {
				let data = {
					// professionId: this.professionId,
					// condition: 0
					schoolId: this.schoolId
				}
				apis.getAssetCount(data).then(res => {
					if(res.data!=undefined){
						if (res.data.oneAsset > 0) {
							res.data.oneAsset = (res.data.oneAsset / 10000).toFixed(2)
						}
						if (res.data.twoAsset > 0) {
							res.data.twoAsset = (Number(res.data.twoAsset) / 10000).toFixed(2)
						}
						if (res.data.threeAsset > 0) {
							res.data.threeAsset = (Number(res.data.threeAsset) / 10000).toFixed(2)
						}
						this.assetForm = res.data
					}

				})
			},
			getOutList() {
				let data = {
					professionId: this.professionId,
					// condition: 0
				}
				apis.getOutList(data).then(res => {
					// console.log(res)
					this.tableList = res.data
				})
			},
			getResource() {
				let data = {
					professionId: this.professionId,
					// condition: 0
				}
				apis.getResource(data).then(res => {
					if(res.data!=undefined){
						this.resourceForm = res.data
					}

				})
			},
			getTraining() {
				let data = {
					professionId: this.professionId,
					// condition: 0
				}
				apis.getTraining(data).then(res => {
					// console.log(res,'getTraining')
					if(res.data!=undefined){
						this.trainingForm = res.data
					}

				})
			},
			getScientific() {
				let data = {
					// professionId: this.professionId,
					// condition: 0
					schoolId:this.schoolId
				}
				apis.getScientific(data).then(res => {
					// console.log(res)
					if(res.data!=undefined){
						this.scientificForm = res.data
					}

				})
			},
			getCertificate() {
				let data = {
					professionId: this.professionId,
					// condition: 0
				}
				apis.getCertificate(data).then(res => {
					this.certificateData.xdata = []
					this.certificateData.data = []
					this.certificateData2 = []
					for (let i = 0; i < res.data.length; i++) {
						this.certificateData.xdata.push(res.data[i].name)
						this.certificateData.data.push(res.data[i].num)
						this.certificateData2.push(res.data[i].num)
					}
					// this.pushForm = res.data
				})
			},
			getStudent() {
				let data = {
					professionId: this.professionId,
					// grade:2019
					// professionId: 70,
					// condition: 0
				}
				apis.getStudent(data).then(res => {
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].rate = ((Number(res.data[i].readingStudent) / Number(res.data[i]
							.initStudent)) * 100).toFixed(0)
					}
					this.stuData = res.data
					let data = {
						list: res.data
					}
					data = JSON.stringify(data)
					localStorage.setItem('stuData', data)
					// console.log(localStorage.getItem('stuData'))
				})
			},
			getGraduate() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
				}
				apis.getGraduate(data).then(res => {
					this.studentAmount = res.data.studentAmount.toString().split('')

					this.sum = Number(res.data.studyingAmount) + Number(res.data.internshipAmount) + Number(res
						.data.examineAmount) + Number(res.data.jobSearchAmount) + Number(res.data.jobAmount)
					// console.log(this.jobInfoData)
				})
			},

			stuNum() {
				this.stuNums = this.$echarts.init(document.getElementById("stuNumEcharts")); //获取dom
				new stuNumEcharts(this.stuNums, this.$echarts, this.stuData);
			},
			certificate() {
				this.certificates = this.$echarts.init(document.getElementById("certificateEcharts")); //获取dom
				new certificateEcharts(this.certificates, this.$echarts, this.certificateData);
			},
			jobInfo() {
				this.jobInfos = this.$echarts.init(document.getElementById("jobInfoEcharts")); //获取dom
				new jobInfoEcharts(this.jobInfos, this.$echarts, this.jobData);
			},
			internship() {
				this.internships = this.$echarts.init(document.getElementById("internshipEcharts")); //获取dom
				new internshipEcharts(this.internships, this.$echarts, this.internshipData);
			},


		}
	}
</script>

<style lang="scss" scoped>
	.screen {
		width: 100%;
		height: calc(100% - 80px);
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		// background-image: url("../assets/video.mp4");
		// background-size: 75% 75%;
		// background-repeat: no-repeat;
		// background-position: center 0%;

		>div {
			height: 100%;
		}

		>.left {
			width: 27%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			>div {
				padding: 20px;
				// background-image: url("../assets/border.png");
				// background-size: 100% 100%;
				// background-position: center center;
			}

			>.leftTop {
				width: 100%;
				height: 59%;

				>.teaching {
					padding: 10px 20px;
					height: 33%;

					>.teachingCon {
						margin-top: 20px;
						display: flex;
						justify-content: space-between;

						>div {

							>span {
								font-size: 18px;

							}

							>p {
								margin-top: 20px;
								font-size: 16px;
								color: rgba(255, 255, 255, 0.6);

								>span {
									font-size: 33px;
									color: #129BFF;
									font-weight: 600;
									margin-right: 5px;
								}
							}

							>p:nth-of-type(2) {
								margin-top: 10px;

								>span {
									font-size: 16px;
									color: rgba(72, 255, 178, 1);
								}
							}
						}

					}
				}

				// >.process {
				// 	width: 100% !important;
				// 	height: 25%;


				// 	>.teachingCon {
				// 		margin-top: 20px;
				// 		display: flex;
				// 		justify-content: space-between;
				// 		padding: 0 20px;

				// 		>div {

				// 			>span {
				// 				font-size: 18px;

				// 			}

				// 			>p {
				// 				margin-top: 20px;
				// 				font-size: 16px;
				// 				color: rgba(255, 255, 255, 0.6);

				// 				>span {
				// 					font-size: 33px;
				// 					color: #129BFF;
				// 					font-weight: 600;
				// 					margin-right: 5px;
				// 				}
				// 			}

				// 			>p:nth-of-type(2) {
				// 				margin-top: 10px;

				// 				>span {
				// 					font-size: 16px;
				// 					color: rgba(72, 255, 178, 1);
				// 				}
				// 			}
				// 		}

				// 	}
				// }
			}

			>.leftBot {
				height: 39%;
			}
		}

		>.center {
			width: 45%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			>.school {
				height: 3%;

				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				background-clip: text;
				text-fill-color: transparent;
				font-size: 20px;
				font-weight: bold;

			}

			// >.status {
			// 	width: 100%;
			// 	height: 59%;
			// 	// margin: auto;
			// 	// border: 1px solid #00FFF3;
			// 	position: relative;

			// 	>.bgimg {
			// 		background: no-repeat center;
			// 		// background-image: url("~@/assets/piebg.png");
			// 		background-image: url("~@/assets/indexcenBg.png");
			// 		background-size: 100% 100%;
			// 		width: 110%;
			// 		height: 90%;
			// 		position: absolute;
			// 		z-index: 0;
			// 		margin-top: 140px;
			// 		margin-left: -50px;
			// 	}
			// }
			>.status {
				width: 100%;
				height: 55%;
				margin: auto;
				// padding-top: 20px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				>.subtitleCen {
					position: absolute;
					left: 0;
					top: -10px;
				}

				>.stunum {
					position: absolute;
					z-index: 2;
					text-align: center;

					>div {
						display: flex;
						justify-content: center;
						margin-top: 10px;

						>div {
							background-image: url("../assets/numbg.png");
							background-size: 100% 100%;
							background-position: center center;
							width: 46px;
							height: 46px;
							text-align: center;
							line-height: 46px;
							color: #00FFF3;
							font-size: 30px;
							font-weight: 550;
							margin: 0 5px;
						}
					}

					>span {
						font-weight: 600;
						font-size: 20px;
						color: #129BFF;
						// background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
						// -webkit-background-clip: text;
						// -webkit-text-fill-color: transparent;
						// background-clip: text;
						// text-fill-color: transparent;
					}

				}


				>.stuCon {
					position: relative;
					width: 100%;
					height: 100%;

					>div:nth-of-type(1) {
						position: absolute;
						left: 50px;
						top: 80px;
						background-image: url('../assets/num1.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 255px;
						height: 150px;
						display: flex;
						// justify-content: space-between;
						line-height: 100px;
						padding: 0 26px 0 10px;

						>p {
							// width
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							padding-left: 10px;
						}

						>div {
							width: 65%;
							display: flex;
							// justify-content: space-between;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #46AEF9;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #46AEF9;
								font-weight: 500;
							}
						}
					}

					.current1 {
						background-image: url('../assets/num5.png') !important;
					}

					>div:nth-of-type(2) {
						position: absolute;
						right: 150px;
						top: 0px;
						background-image: url('../assets/num2.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 255px;
						height: 150px;
						display: flex;
						// justify-content: space-between;
						line-height: 100px;
						padding: 0 0px 0 30px;

						>p {
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							padding-left: 6px;
						}

						>div {
							width: 70%;
							display: flex;
							padding-left: 18px;
							// justify-content: space-between;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #46AEF9;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #46AEF9;
								font-weight: 500;
							}
						}
					}

					.current2 {
						background-image: url('../assets/num6.png') !important;
					}

					>div:nth-of-type(3) {
						position: absolute;
						right: 0px;
						bottom: 230px;
						background-image: url('../assets/num4.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 310px;
						height: 100px;
						display: flex;
						// justify-content: space-between;
						line-height: 100px;
						padding: 0 0px 0 85px;

						>p {
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							// padding-right: 65px;
						}

						>div {
							width: 70%;
							display: flex;
							padding-left: 18px;
							// justify-content: space-between;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #46AEF9;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #46AEF9;
								font-weight: 500;
							}
						}
					}

					.current3 {
						background-image: url('../assets/num8.png') !important;
					}

					>div:nth-of-type(4) {
						position: absolute;
						right: 100px;
						bottom: 30px;
						background-image: url('../assets/num4.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 310px;
						height: 100px;
						display: flex;
						// justify-content: space-between;
						line-height: 100px;
						padding: 0 0px 0 30px;

						>p {
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							padding-left: 55px;
						}

						>div {
							width: 58%;
							display: flex;
							padding-left: 18px;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #46AEF9;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #46AEF9;
								font-weight: 500;
							}
						}
					}

					.current4 {
						background-image: url("../assets/num8.png") !important;
					}

					>div:nth-of-type(5) {
						position: absolute;
						left: 0px;
						bottom: 100px;
						background-image: url('../assets/num9.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 310px;
						height: 100px;
						display: flex;
						// justify-content: space-between;
						line-height: 100px;
						padding: 0 62px 0 10px;

						>p {
							font-size: 18px;
							font-weight: 500;
							color: #42647C;
							padding-left: 7px;
						}

						>div {
							width: 65%;
							display: flex;
							// justify-content: space-between;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #42647C;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #42647C;
								font-weight: 500;
							}
						}
					}

					.current5 {
						background-image: url("../assets/num10.png") !important;
					}
				}
			}



			>.cenbottom {
				width: 100%;
				height: 8%;

				// border: 2px solid #0098fa;
				// border-radius: 8px;
				// border: 1px solid #00FFF3;
				>.rate {
					height: 8%;
					display: flex;
					justify-content: space-between;


					>div {
						display: flex;
						justify-content: space-between;

						>img {
							width: 64px;
							height: 64px;
						}

						>div {
							height: 64px;
							display: flex;
							justify-content: space-between;
							flex-direction: column;
							margin-left: 20px;

							>span {
								font-size: 18px;
								color: rgba(255, 255, 255, 1);
							}

							>p {
								font-size: 18px;
								color: rgba(255, 255, 255, 1);

								>span {
									color: rgba(255, 255, 255, 1);
									font-size: 32px;
									font-weight: bold;
								}
							}
						}
					}
				}



			}

			>.stuImg {
				height: 29%;
				// border: 2px solid #0098fa;
				border-radius: 8px;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;
				// border: 1px solid #00FFF3;

				>div {
					width: 49%;
					height: 100%;
					// border: 2px solid #0098fa;
					border-radius: 8px;
					position: relative;
				}

				#internshipEcharts {
					transform: rotateX(45deg);
				}

				.chartBg {
					background-image: url("../assets/chartBg2.png");
					background-size: 100% 100%;
					background-position: center center;
					width: 100%;
					height: 90%;
					position: absolute;
					z-index: 0;
					top: 15%;
				}

				.value {
					position: absolute;
					top: 55%;
					left: 50%;
					transform: translate(-50%, -50%);

					color: #fff;

					>p {
						font-size: 30px;
						font-weight: 600;
						margin-bottom: 10px;
					}

					>span {
						font-size: 16px;

					}
				}
			}
		}

		>.right {
			width: 27%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			>div {
				width: 100%;
				height: 32%;
				padding: 20px;
				// background-image: url("../assets/border.png");
				// background-size: 100% 100%;
				// background-position: center center;
			}

			>.stuInfo {

				>div:nth-of-type(2) {
					display: flex;
					justify-content: space-around;
					margin-top: 20px;

					>div {
						width: 35%;
						height: 200px;
						background-image: url("../assets/job.png");
						background-size: 100% 100%;
						background-position: center center;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding-top: 50px;
						cursor: pointer;

						>p:nth-of-type(1) {
							font-size: 18px;
							color: #C5C5C5;
							// margin-top: 50px;
						}

						>p:nth-of-type(2) {
							font-size: 24px;
							color: #01C2FF;
							margin-top: 10px;

							>span {
								font-size: 14px;
								color: #C5C5C5;
							}
						}

						// height: 151px;
					}

					>div:nth-of-type(1) {
						background-image: url("../assets/company.png");
					}

					>div:hover {
						transform: scale(1.1);
					}
				}
			}



		}
	}

	.stu {
		display: flex;
		justify-content: space-around;
		padding: 0 40px;
		margin-top: 20px;

		>div {
			width: 160px;
			height: 224px;

			>img {
				width: 100%;
				height: 100%;
			}
		}

		>div:nth-of-type(2) {
			width: 48%;
			height: 224px;
			background-image: url("../assets/nameBg.png");
			background-size: 100% 100%;
			background-position: center center;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 0 10px;

			>p {
				font-size: 23px;
				color: #129BFF;
				font-weight: 500;
				margin-bottom: 10px;
			}

			>div {
				color: #129BFF;
				margin-bottom: 5px;
				font-size: 14px;

			}
		}
	}

	.subtitle {
		width: 100%;
		height: 33px;
		display: flex;
		position: relative;
		align-items: center;

		>p {
			font-size: 15px;
			font-weight: bold;
			height: 33px;
			background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
			background-clip: text;
			text-fill-color: transparent;
			line-height: 33px;
			color: transparent;
		}

		>div {
			width: 100%;
			display: flex;
			justify-content: space-between;

			>p {
				font-size: 15px !important;
				font-weight: bold !important;
				height: 33px !important;
				background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%) !important;
				background-clip: text !important;
				text-fill-color: transparent !important;
				line-height: 33px !important;
				color: transparent !important;
			}
		}

		>span {
			display: block;
			width: 3.85px;
			height: 15.41px;
			background: #3FB2F9;
			margin-right: 10px;
			border-radius: 30px;
		}

		>img {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
		}
	}

	.subtitle2 {
		background: #0098FA;
		width: 84px;
		padding: 0 10px;
		// box-sizing: border-box;
		height: 25px;
		text-align: center;
		line-height: 25px;
		border-radius: 8px 0px 8px 0px;
		font-size: 12px;
	}

	::v-deep .el-input__inner {
		background: #0A469E !important;
		border: 0.91px solid #0A469E !important;
		color: #fff !important;
	}

	.img {
		width: 73px;
		height: 84px;

		>img {
			width: 100%;
			height: 100%;
		}
	}

	.name {
		background-image: url("../assets/nameBg.png");
		background-size: 100% 100%;
		background-position: center center;
		width: 73px;
		height: 25px;
		text-align: center;
		line-height: 25px;
	}

	.bgimg {
		background: no-repeat center;
		background-image: url("~@/assets/image9.png");
		background-size: 100% 100%;
		background-position: center bottom;
		width: 90%;
		height: 135%;
		position: absolute;
		z-index: 0;
		// margin-top:40px;

	}

	@-webkit-keyframes rotation {

		0% {
			transform: translateX(0%) rotateX(0deg) rotateZ(0)
		}

		100% {
			transform: translateX(0%) rotateX(0deg) rotateZ(360deg)
		}

	}

	.an {
		animation: rotation 20s linear infinite;
	}

	.custom-tooltip {
		padding: 0 !important;
		border: none !important;
		background-color: transparent !important;
		background: url('~@/assets/tooltip.png') center center no-repeat;
		background-size: 100% 100%;
		width: 120px;
		height: 120px;
		color: #fff;
		justify-content: start;
	}
</style>
<style type="text/css">
	.el-select-dropdown {
		background: #0A469E !important;
		color: #fff !important;
		border: 0.91px solid #0A469E !important;
		font-size: 12px !important;
		/* height: 20px !important;
		line-height: 20px !important; */

	}

	.el-select-dropdown__item {
		color: #fff !important;
		font-size: 12px !important;
		background: #0A469E !important;
	}

	.el-select-dropdown__item.hover {
		color: #fff !important;
		background: #0A469E !important;
		font-size: 12px !important;

	}

	/* .tab-con>div {
		display: flex;
		width: 100% !important;

	} */
</style>
