export default class {
	constructor(charts,echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(seriesdata, echarts) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(seriesdata, echarts) {
		// function Pie() {
		// 	let dataArr = [];
		// 	for (var i = 0; i < 150; i++) {
		// 		if (i % 2 === 0) {
		// 			dataArr.push({
		// 				name: (i + 1).toString(),
		// 				value: 50,
		// 				itemStyle: {
		// 					normal: {
		// 						color: '#00AFFF',
		// 						borderWidth: 0,
		// 						borderColor: 'rgba(0,0,0,0)',
		// 					},
		// 				},
		// 			});
		// 		} else {
		// 			dataArr.push({
		// 				name: (i + 1).toString(),
		// 				value: 100,
		// 				itemStyle: {
		// 					normal: {
		// 						color: 'rgba(0,0,0,0)',
		// 						borderWidth: 0,
		// 						borderColor: 'rgba(0,0,0,0)',
		// 					},
		// 				},
		// 			});
		// 		}
		// 	}
		// 	return dataArr;
		// }
		var ratio = (Number(seriesdata) * 100).toFixed(0); //占比数值  总数为100
		var ratioLength = 3; //波浪数量
		var ratioArr = [];
		var ratioColor = ['#1991d2', '#3831f3', '#1991d2']; //波浪内颜色
		for (var ratioIndex = 0; ratioIndex < ratioLength; ratioIndex++) {
			ratioArr.push(ratio / 100)
		}
		let option = {
			// title: {
			// 	text: ratio + '%',
			// 	textStyle: {
			// 		fontSize: 20,
			// 		fontFamily: 'Microsoft Yahei',
			// 		fontWeight: 'bold',
			// 		color: '#fff'
			// 	},
			// 	x: 'center',
			// 	y: 'center'
			// },
			series: [{
					type: 'liquidFill',
					radius: '90%',
					center: ['50%', '50%'],
					color: ratioColor,
					data: ratioArr,
					backgroundStyle: {
						// borderWidth: 1,
						color: '#1f2c52'
					},
					label: {
						normal: {
							// formatter: 0.87 * 100 + '\n%',
							formatter: ratio + '%\n{d|专业对口率}',
							//  formatter: function(params){
							//     return params.value* 100 + " \n%";
							// },
							rich: {
								d: {
									fontSize: 16,
								},

							},
							textStyle: {
								fontSize: 30,
								color: '#fff',
							},
						},
					},
					outline: {
						show: false,
					}
				},
				{ //细的外圈
					type: "pie",
					center: ["50%", "50%"],
					radius: ["89%", "90%"],
					hoverAnimation: false,
					data: [{
							name: "",
							value: (Number(seriesdata) * 100).toFixed(0),
							labelLine: {
								show: false
							},
							itemStyle: {
								color: 'transparent'
							},
							emphasis: {
								labelLine: {
									show: false
								},
								itemStyle: {
									color: 'transparent'
								},
							}
						},


						{ //画剩余的刻度圆环
							name: "",
							value: (Number(seriesdata) * 100).toFixed(0),
							itemStyle: {
								color: '#41496b'
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							},
							emphasis: {
								labelLine: {
									show: false
								},
								itemStyle: {
									color: '#41496b'
								},
							}
						}
					]
				},
				{ //粗的外圈
					type: "pie",
					center: ["50%", "50%"],
					radius: ["88%", "91%"],
					hoverAnimation: false,
					zlevel: 2,
					data: [


						{
							name: "",
							value: (Number(seriesdata) * 100).toFixed(0),
							labelLine: {
								show: false
							},
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#00e2fe'
								}, {
									offset: 1,
									color: '#0707ff'
								}]),
							},
							emphasis: {
								labelLine: {
									show: false
								},
								itemStyle: {
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#00e2fe'
									}, {
										offset: 1,
										color: '#0707ff'
									}]),
								},
							}
						},


						{ //画剩余的刻度圆环
							name: "",
							value: (Number(seriesdata) * 100).toFixed(0),
							itemStyle: {
								color: 'transparent'
							},
							label: {
								show: false
							},
							labelLine: {
								show: false
							},
							emphasis: {
								labelLine: {
									show: false
								},
								itemStyle: {
									color: 'transparent'
								},
							}
						}
					]
				}

			]
			// series: [{
			// 		// value: 50, //  内容 配合formatter
			// 		type: 'liquidFill',
			// 		radius: '70%', // 控制中间圆球的尺寸（此处可以理解为距离外圈圆的距离控制）
			// 		center: ['50%', '50%'],
			// 		data: [
			// 			{
			// 				value: seriesdata,
			// 				direction: 'left', //波浪方向
			// 			},
			// 		], // data个数代表波浪数
			// 		backgroundStyle: {
			// 			borderWidth: 1,
			// 			color: 'rgba(62, 208, 255, 1)', // 球体本景色
			// 		},
			// 		amplitude: '6  %', //波浪的振幅
			// 		// 修改波浪颜色
			// 		// color: ['#0286ea', 'l#0b99ff'], // 每个波浪不同颜色，颜色数组长度为对应的波浪个数
			// 		color: [{
			// 			type: 'linear',
			// 			x: 0,
			// 			y: 0,
			// 			x2: 0,
			// 			y2: 1,
			// 			colorStops: [{
			// 					offset: 1,
			// 					color: '#6CDEFC',
			// 				},
			// 				{
			// 					offset: 0,
			// 					color: '#429BF7',
			// 				},
			// 			],
			// 			globalCoord: false,
			// 		}, ],
			// 		label: {
			// 			normal: {
			// 				// formatter: 0.87 * 100 + '\n%',
			// 				formatter: seriesdata * 100 + '%\n{d|专业对口率}',
			// 				//  formatter: function(params){
			// 				//     return params.value* 100 + " \n%";
			// 				// },
			// 				rich: {
			// 					d: {
			// 						fontSize: 16,
			// 					},

			// 				},
			// 				textStyle: {
			// 					fontSize:30,
			// 					color: '#fff',
			// 				},
			// 			},
			// 		},
			// 		outline: {
			// 			show: false,
			// 		},
			// 	},
			// 	{
			// 		type: 'pie',
			// 		z: 1,
			// 		center: ['50%', '50%'],
			// 		radius: ['72%', '73.5%'], // 控制外圈圆的粗细
			// 		hoverAnimation: false,
			// 		data: [{
			// 			name: '',
			// 			value: 500,
			// 			labelLine: {
			// 				show: false,
			// 			},
			// 			itemStyle: {
			// 				color: '#00AFFF',
			// 			},
			// 			emphasis: {
			// 				labelLine: {
			// 					show: false,
			// 				},
			// 				itemStyle: {
			// 					color: '#00AFFF',
			// 				},
			// 			},
			// 		}, ],
			// 	},
			// 	{
			// 		//外发光
			// 		type: 'pie',
			// 		z: 1,
			// 		zlevel: -1,
			// 		radius: ['70%', '90.5%'],
			// 		center: ['50%', '50%'],
			// 		hoverAnimation: false,
			// 		clockWise: false,
			// 		itemStyle: {
			// 			normal: {
			// 				borderWidth: 20,
			// 				color: 'rgba(224,242,255,1)',
			// 			},
			// 		},
			// 		label: {
			// 			show: false,
			// 		},
			// 		data: [100],
			// 	},
			// 	{
			// 		//底层外发光
			// 		type: 'pie',
			// 		z: 1,
			// 		zlevel: -2,
			// 		radius: ['70%', '100%'],
			// 		center: ['50%', '50%'],
			// 		hoverAnimation: false,
			// 		clockWise: false,
			// 		itemStyle: {
			// 			normal: {
			// 				borderWidth: 20,
			// 				color: 'rgba(224,242,255,.4)',
			// 			},
			// 		},
			// 		label: {
			// 			show: false,
			// 		},
			// 		data: [100],
			// 	},
			// 	{
			// 		type: 'pie',
			// 		zlevel: 0,
			// 		silent: true,
			// 		radius: ['78%', '80%'],
			// 		z: 1,
			// 		label: {
			// 			normal: {
			// 				show: false,
			// 			},
			// 		},
			// 		labelLine: {
			// 			normal: {
			// 				show: false,
			// 			},
			// 		},
			// 		data: Pie(),
			// 	},
			// ],

			// grid: {
			// 	top: 0,
			// 	bottom: 0,
			// },
			// angleAxis: {
			// 	show: false,
			// 	max: (100 * 360) / 270, //-45度到225度，二者偏移值是270度除360度
			// 	type: 'value',
			// 	startAngle: 225, //极坐标初始角度
			// 	splitLine: {
			// 		show: false,
			// 	},
			// },
			// barMaxWidth: 20, //圆环宽度
			// radiusAxis: {
			// 	show: false,
			// 	type: 'category',
			// },
			// //圆环位置和大小
			// polar: {
			// 	center: ['50%', '50%'],
			// 	radius: '170%',
			// },

			// series: [{
			// 		type: 'bar',
			// 		data: [{
			// 			//上层圆环，显示数据
			// 			value: 60,
			// 			itemStyle: {
			// 				color: '#0098FA',
			// 			},
			// 		}, ],
			// 		barGap: '-100%', //柱间距离,上下两层圆环重合
			// 		coordinateSystem: 'polar',
			// 		roundCap: true, //顶端圆角
			// 		z: 3, //圆环层级，同zindex
			// 	},
			// 	{
			// 		//下层圆环，显示最大值
			// 		type: 'bar',
			// 		data: [{
			// 			value: 100,
			// 			itemStyle: {
			// 				color: '#C5E1FF',
			// 				opacity: 1,
			// 				borderWidth: 0,
			// 			},
			// 		}, ],
			// 		barGap: '-100%',
			// 		coordinateSystem: 'polar',
			// 		roundCap: true,
			// 		z: 1,
			// 	},
			// 	//仪表盘
			// 	{
			// 		type: 'gauge',
			// 		startAngle: 225, //起始角度，同极坐标
			// 		endAngle: -45, //终止角度，同极坐标
			// 		axisLine: {
			// 			show: false,
			// 		},
			// 		splitLine: {
			// 			show: false,
			// 		},
			// 		axisTick: {
			// 			show: false,
			// 		},
			// 		axisLabel: {
			// 			show: false,
			// 		},
			// 		splitLabel: {
			// 			show: false,
			// 		},
			// 		pointer: {
			// 			// 分隔线
			// 			shadowColor: 'auto', //默认透明
			// 			shadowBlur: 5,
			// 			length: '50%',
			// 			width: '2',
			// 			show: false,
			// 		},
			// 		itemStyle: {
			// 			color: '#1598FF',
			// 			borderColor: '#1598FF',
			// 			borderWidth: 6,
			// 		},
			// 		detail: {
			// 			formatter: function() {
			// 				// return '完成率\n'+getvalue + '%';
			// 				return `{wcl|专业对口率\n}{number|${60 + '%'}}`;
			// 			},
			// 			rich: {
			// 				number: {
			// 					fontSize: 30,
			// 					textAlign: 'center',
			// 					color: '#fff'
			// 				},
			// 				wcl: {
			// 					fontSize: 16,
			// 					textAlign: 'center',
			// 					color: '#fff'
			// 				},
			// 			},
			// 			color: '#fff',
			// 			lineHeight: 30,
			// 			offsetCenter: ['0', '0'],
			// 		},
			// 		title: {
			// 			show: false,
			// 		},
			// 		data: [{
			// 			value: 60,
			// 		}, ],
			// 	},
			// 	{
			// 		name: '外部刻度',
			// 		type: 'gauge',
			// 		//  center: ['20%', '50%'],
			// 		radius: '70%',
			// 		min: 0, //最小刻度
			// 		max: 100, //最大刻度
			// 		splitNumber: 10, //刻度数量
			// 		startAngle: 225,
			// 		endAngle: -45,
			// 		axisLine: {
			// 			show: true,
			// 			lineStyle: {
			// 				width: 1,
			// 				color: [
			// 					[1, 'rgba(0,0,0,0)']
			// 				],
			// 			},
			// 		}, //仪表盘轴线
			// 		axisLabel: {
			// 			show: false,
			// 			color: '#4d5bd1',
			// 			distance: 25,
			// 		}, //刻度标签。
			// 		axisTick: {
			// 			show: true,
			// 			splitNumber: 7,
			// 			lineStyle: {
			// 				color: '#086CAF', //用颜色渐变函数不起作用
			// 				width: 2,
			// 			},
			// 			length: -8,
			// 		}, //刻度样式
			// 		splitLine: {
			// 			show: false,
			// 			length: -20,
			// 			lineStyle: {
			// 				color: '#C7CBCF', //用颜色渐变函数不起作用
			// 			},
			// 		}, //分隔线样式
			// 		detail: {
			// 			show: false,
			// 		},
			// 		pointer: {
			// 			show: false,
			// 		},
			// 	},
			// ],

		};

		return option;
	}
}