<template>
	<div>
		<div class="nav" v-if="$route.query.professionId != '65'">
			<div>
				<div class="navImg" v-if="!text1" @mouseover="text1=true">
					<img src="../assets/zh.png" alt="">
				</div>
				<div class="navTxt" v-if="text1" @mouseleave="text1=false" @click="changeNow(0)">
					<img src="../assets/zh.png" alt="">
					<span>综合数据</span>
				</div>
			</div>
			<div>
				<div class="navImg" v-if="!text2" @mouseover="text2=true">
					<img src="../assets/jy.png" alt="">
				</div>
				<div class="navTxt" v-if="text2" @mouseleave="text2=false" @click="changeNow(1)">
					<img src="../assets/jy.png" alt="">
					<span>实习就业</span>
				</div>
			</div>
			<div>
				<div class="navImg" v-if="!text3" @mouseover="text3=true">
					<img src="../assets/sx.png" alt="">
				</div>
				<div class="navTxt" v-if="text3" @mouseleave="text3=false" @click="changeNow(2)">
					<img src="../assets/sx.png" alt="">
					<span>教学数据</span>
				</div>
			</div>
		</div>
		<div id="index" ref="appRef">

			<div class="bg">
				<dv-loading v-if="loading">Loading...</dv-loading>
				<div class="body">
					<div class="title" v-if="now==0">{{form.schoolName}}{{form.secondSchoolName}}</div>
					<div class="title" v-if="now==1">{{form.schoolName}}{{form.secondSchoolName}}实习就业统计</div>
					<div class="title" v-if="now==2">{{form.schoolName}}{{form.secondSchoolName}}</div>
					<dv-decoration-2 :color="['#0098FA']" class="leftLine" style="width:500px;height:10px;" />
					<dv-decoration-2 :color="['#0098FA']" class="rightLine" style="width:500px;height:10px;" />
					<div class="dayInfo">
						<div>
							<span>{{nowDate}}</span>
							<span>{{nowWeek}}</span>
							<span style="color: #fff;">{{nowTime}}</span>
						</div>
					</div>
					<indexView v-if="now==0&&$route.query.professionId != '65'" :class='now==0?"fade":""' :form='form'>
					</indexView>
					<jobView v-if="now==1" :class='now==1?"fade":""' :form='form'></jobView>
					<teachingView v-if="now==2&&$route.query.professionId != '65'" :class='now==2?"fade":""'
						:form='form'></teachingView>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import drawMixin from "../utils/drawMixin";
	import jobView from "./jobView3.vue";
	import indexView from "./indexView3.vue";
	import teachingView from "./teachingView3.vue";
	// import jobView from "./jobView2.vue";
	// import indexView from "./indexView2.vue";
	// import internshipView from "./internshipView2.vue";
	import apis from '@/api/api'
	export default {
		components: {
			jobView,
			indexView,
			teachingView
			// swiper,
			// statusCharts
		},
		mixins: [drawMixin],
		data() {
			return {
				loading: true,
				nowDate: null,
				nowTime: null,
				nowWeek: null,
				now: 0,
				text1: false,
				text2: false,
				text3: false,
				timer2: null,
				timer: null,
				expireTime: 60000,
				form: {}

			}
		},

		mounted() {
			this.cancelLoading()
			this.timer = setInterval(() => {
				this.setNowTimes()
			}, 1000)
			if (this.$route.query.professionId != '65') {
				this.timer2 = setInterval(() => {
					if (this.now == 2) {
						this.now = 0
					} else {
						this.now = this.now + 1
					}
				}, 60000)
			} else {
				this.now = 1
			}

		},


		beforeDestroy() {
			//清除定时器
			clearInterval(this.timer);
			this.timer = null;
			if (this.$route.query.professionId != '65') {
				clearInterval(this.timer2);
				this.timer2 = null
			}

		},

		created() {

			this.getProfession()
		},

		methods: {
			getProfession() {
				apis.getProfessionApi(this.$route.query.professionId).then(res => {
					this.form = res.data
					if (this.$route.query.professionId == '65') {
						this.form.secondSchoolName = '数字经济学院'
					}

				})
			},
			changeNow(e) {
				this.now = e
				clearInterval(this.timer2);
				this.timer2 = null
				this.timer2 = setInterval(() => {
					if (this.now == 2) {
						this.now = 0
					} else {
						this.now = this.now + 1
					}
					// console.log(this.expireTime)
				}, 60000)
			},
			setNowTimes() {
				//获取当前时间
				let myDate = new Date()
				let wk = myDate.getDay()
				let yy = String(myDate.getFullYear())
				let mm = myDate.getMonth() + 1
				let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
				let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
				let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
				let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
				let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
				let week = weeks[wk]
				this.nowDate = yy + '-' + mm + '-' + dd
				this.nowTime = hou + ':' + min + ':' + sec
				this.nowWeek = week
			},
			cancelLoading() {
				setTimeout(() => {
					this.loading = false
				}, 500)
			},
		}
	}
</script>

<style lang="scss" scoped>
	// @import '../assets/scss/index.scss';
	.nav {
		position: fixed;
		z-index: 999;
		left: 0;
		top: 100px;

		.navImg {
			cursor: pointer;
			width: 45px;
			height: 45px;
			border-radius: 0px 5px 5px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #15275D;

			>img {
				width: 25px;
				height: 25px;
			}
		}

		.navImg:nth-of-type(1) {
			margin-bottom: 10px;
		}

		.navTxt {
			cursor: pointer;
			// width: 145px;
			padding: 0 20px 0 10px;
			height: 45px;
			border-radius: 0px 38.45px 38.45px 0px;
			line-height: 45px;
			display: flex;
			align-items: center;
			// justify-content: center;
			background: #15275D;

			>img {
				width: 25px;
				height: 25px;
				margin-right: 5px;
			}

			>span {
				font-size: 15px;
				color: #129BFF;
			}
		}

		.navTxt:nth-of-type(1) {
			margin-bottom: 10px;
		}
	}

	#index {
		background: #11123a;
		width: 1920px;
		height: 1080px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform-origin: left top;
		// overflow: hidden; 
		color: #fff;


		>.bg {
			width: 100%;
			height: 100%;
			padding: 0 20px;
			background: #11123a;

			>.body {
				width: 100%;
				height: 100%;
				padding: 0 20px;
				position: relative;

				>.title {
					letter-spacing: 2px;
					width: 47%;
					margin: auto;
					height: 80px;
					background-image: url("../assets/title.png");
					background-size: 100% 100%;
					background-position: center center;
					text-align: center;
					color: #6AE1FF;
					font-size: 25px;
					font-weight: 550;
					line-height: 60px;
				}

				>.leftLine {
					position: absolute;
					transform: rotateY(180deg);
					top: 45px;
					left: 30px;
				}

				>.rightLine {
					position: absolute;
					top: 45px;
					right: 30px;
				}

				>.dayInfo {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					font-size: 18px;
					position: absolute;
					right: 0;
					top: 15px;
					text-align: right;

					>div {
						color: #45A2FF;
						width: 100%;
						display: flex;
						justify-content: flex-end;
						// justify-content: space-around;
						text-align: right;

						>span {
							display: block;
							width: 9%;
							text-align: center;
						}
					}
				}
			}
		}
	}

	.fade {
		animation: fadeInAnimation ease 1s;
		// animation-iteration-count: 1;
		// animation-fill-mode: forwards;
	}

	@keyframes fadeInAnimation {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
</style>