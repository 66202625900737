<template>
	<div class="screen">
		<div class="left">
			<div class="jobNum">
				<div class="num">
					<div>
						<div>职位推荐</div>
						<div>
							<p>{{pushForm.pushPresentAmount}} <span>次</span> </p>
							<p>
								<i v-if='pushForm.pushQOQRate<0' class="el-icon-caret-bottom"></i>
								<i v-if='pushForm.pushQOQRate>0' class="el-icon-caret-top"></i>
								<span> {{pushForm.pushPreviousAmount}}%</span>
								<span>较上周</span>
							</p>
						</div>
					</div>
					<div>
						<div>简历投递</div>
						<div>
							<p>{{sendForm.sendPresentAmount}} <span>次</span> </p>
							<p>
								<i v-if='sendForm.sendQOQRate<0' class="el-icon-caret-bottom"></i>
								<i v-if='sendForm.sendQOQRate>0' class="el-icon-caret-top"></i>
								<span>{{sendForm.sendPreviousAmount}}%</span>
								<span>较上周</span>
							</p>
						</div>
					</div>
				</div>
				<div class="subtitle" style='margin-top:20px;'>
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<div>
						<p>职位推荐职位投递数量</p>
						<el-select v-model="condition" size="mini" placeholder="请选择" style="width: 85px;"
							@change="conditionChange">
							<el-option label="月" :value="1">
							</el-option>
							<el-option label="周" :value="0">
							</el-option>
						</el-select>
					</div>

				</div>
				<div style="width: 100%;height: 70%;" id="postNumEcharts"></div>
			</div>
			<div class="postInfo">
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>简历投递详情</p>
				</div>
				<div style="width: 100%;height: 90%;" id="postInfoEcharts"></div>
			</div>
			<div class="companyJob">
				<div class="subtitle2" style="margin-bottom: 10px;">
					参与实习
				</div>
				<internship></internship>
			</div>
		</div>
		<div class="center">
			<!-- <div class="topLeft"> -->
			<div class="school">
				<p>{{form.professionName}}{{$route.query.grade}}级</p>
				<div class="stunum">
					<span>学生数量</span>
					<div>
						<div v-for="(item,index) in studentAmount" :key='index'>{{item}}</div>
					</div>
					<span>人</span>
				</div>

			</div>
			<div class="status">
				<div class="subtitle subtitleCen">
					<!-- <img src="../assets/titlebg.png" alt=""> -->
					<span></span>
					<p>就业去向</p>
				</div>
				<div style="width: 100%;height: 100%;" id="sdMap" ref="receivinges"></div>
			</div>
			<div class="rate" style="display: flex;justify-content: space-around;">
				<div>
					<p>备考升学率</p>
					<p style="color: #EB8169;">{{rateForm.examineRate||0}}% <span
							style='margin-left:10px;font-size:14px'>
							{{rateForm.examineAmount||0}}人
						</span></p>
					<!-- <p style="background: #EB8169;">{{rateForm.examineAmount}}人</p> -->
				</div>
				<div style="">
					<p>就业组网签率</p>
					<p style="color: #46C4D0;">{{rateForm.jobGroupOnlineRate||0}}% <span
							style='margin-left:10px;font-size:14px'>
							{{rateForm.jobOnlineAmount||0}}人
						</span></p>
					<!-- <p style="background: #46C4D0;">{{rateForm.jobOnlineAmount}}人</p> -->
				</div>
				<div style="">
					<p>就业组就业率</p>
					<p style="color: #0098FA;">{{rateForm.jobGroupEmploymentRate||0}}% <span
							style='margin-left:10px;font-size:14px'>
							{{rateForm.jobGroupAmount||0}}人
						</span> </p>
					<!-- <p style="background: #0098FA;">{{rateForm.jobGroupAmount}}人</p> -->
				</div>
				<div>
					<p>总体就业率</p>
					<p style="color: #F4D925;">{{rateForm.jobRate||0}}% <span style='margin-left:10px;font-size:14px'>
							{{rateForm.jobAmount||0}}人
						</span> </p>
					<!-- <p style="background: #F4D925;">{{rateForm.jobAmount}}人</p> -->
				</div>
				<div style="">
					<p>就业信息网就业率</p>
					<p style="color: #A476F4;">{{rateForm.emwrate||0}}% <span style='margin-left:10px;font-size:14px'>
							{{rateForm.studentAmount||0}}人
						</span></p>
					<!-- <p style="background: #A476F4;right:-25px">{{rateForm.studentAmount}}人</p> -->
				</div>
			</div>
			<div class="cenbottom">
				<div class="jobGones">
					<div class="subtitle2">
						就业状况
					</div>
					<div style="width: 100%;height: 90%;" id="jobEcharts"></div>
					<!-- <div style="width: 90%;height: 80%;margin:auto;" id="jobGoneEcharts"></div> -->
				</div>
				<div class="jobGones">
					<div class="subtitle2">
						薪资水平
					</div>
					<div style="width: 100%;height: 90%;" id="salaryEcharts"></div>
				</div>
			</div>

			<!-- </div> -->
		</div>
		<div class="right">
			<div class='jobInfo'>
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>就业组信息</p>
				</div>
				<div style="width: 100%;height: 90%;" id="jobInfoEcharts"></div>
			</div>
			<div class='preparation'>
				<div class="subtitle">
					<img src="../assets/titlebg.png" alt="">
					<span></span>
					<p>备考升学组信息</p>
				</div>
				<div style="width: 100%;height: 90%;" id="preparationEcharts"></div>
			</div>
			<div class="jobGone">
				<div class="subtitle2">
					就业对口
				</div>
				<div style="width: 100%;height: 90%;" id="majorCounterpartEcharts"></div>
			</div>

		</div>
	</div>

</template>

<script>
	import drawMixin from "../utils/drawMixin";
	import postNumEcharts from "@/components/echart/job/postNum.js";
	import postInfoEcharts from "@/components/echart/job/postInfo.js";
	import jobInfoEcharts from "@/components/echart/job/jobInfo.js";
	import preparationEcharts from "@/components/echart/job/preparation.js";
	import salaryEcharts from "@/components/echart/job/salary.js";
	import majorCounterpartEcharts from "@/components/echart/job/majorCounterpart.js";
	import jobEcharts from "@/components/echart/job/jobStatus.js";
	import internship from '@/components/echart/job/internship.vue'
	import apis from '@/api/api'
	import "echarts-liquidfill";
	import * as echarts from 'echarts';
	import shandong from '@/components/json/shandong.json'
	// import '../../node_modules/echarts/map/js/province/shandong.js'
	export default {
		components: {
			internship
		},
		mixins: [drawMixin],
		data() {
			return {
				loading: true,
				nowDate: null,
				nowTime: null,
				nowWeek: null,
				city: null,
				jobData: [],
				jobNum: {
					xdatas: [],
					seriesdata: [
						[],
						[],
						[],
					],
					data: null
				},
				postInfoData: [],
				jobInfoData: {
					xdata: [],
					seriesdata: []
				},
				preparationData: {
					xdata: [],
					seriesdata: []
				},
				jobGoneData: [],
				// jobGoneData: {
				// 	seriesdata: []
				// },

				regularData: [],
				internshipData: [],
				majorCounterpartData: 0,
				// majorCounterpartData: {
				// 	seriesdata: 0.6
				// },
				statusList: [],
				professionId: 70,
				schoolId: 64,
				grade: 2019,
				condition: 1,
				pushForm: {},
				sendForm: {},
				companyForm: {},
				studentAmount: [],
				rateForm: {},
				typeList: [],
				timer: null,
				timer2: null,
				timer3: null,
				stuForm: {},
				current: 1,
				sum: 0,
				// echarts
				postNums: null,
				postInfos: null,
				jobInfos: null,
				preparations: null,
				jobs: null,
				salarys: null,
				majorCounterparts: null,
				mapChart: null,

			}
		},
		props: {
			form: {
				type: Object
			},
		},
		watch: {
			'jobNum.xdatas'() {
				this.postNum()
			},
			postInfoData() {
				this.postInfo()
			},

			'jobInfoData.seriesdata'() {
				this.jobInfo()

			},
			'preparationData.seriesdata'() {
				this.preparation()
			},
			jobGoneData() {
				this.map()
			},
			regularData() {
				this.salary()
			},
			internshipData() {
				this.salary()
			},
			majorCounterpartData() {
				this.majorCounterpart()
			},
			jobData() {
				this.job()
			}
		},
		mounted() {
			// this.cancelLoading()
			// this.loading = false
			let that = this
			setTimeout(() => {
				that.postNum()
				that.postInfo()
				that.jobInfo()
				that.preparation()
				// that.jobGone()
				that.job()
				that.salary()
				that.majorCounterpart()
				this.$echarts.registerMap('山东', shandong)
				this.map()
				// that.studentStatus()
			}, 1000)
			// this.timer = setInterval(() => {
			// 	this.setNowTimes()
			// }, 1000)

			// this.timer2 = setInterval(() => {
			// 	this.getSendAndPush()
			// 	this.getPushNum()
			// 	this.getSendNum()
			// 	this.getSend()
			// 	this.getGraduate()
			// 	this.getGraduateDetail()
			// 	this.getArea()
			// 	this.getSalary()
			// 	this.getProfession()
			// }, 300000)
		},


		beforeDestroy() {
			this.postNums.dispose();
			this.postInfos.dispose();
			this.jobInfos.dispose();
			this.preparations.dispose();
			this.jobs.dispose();
			this.salarys.dispose();
			this.majorCounterparts.dispose();
			this.mapChart.dispose();
		},

		created() {
			this.professionId = this.$route.query.professionId
			this.schoolId = this.$route.query.schoolId
			this.grade = this.$route.query.grade
			this.getSendAndPush()
			this.getPushNum()
			this.getSendNum()
			this.getSend()
			// this.getInternshipStu()
			this.getGraduate()
			this.getGraduateDetail()
			this.getArea()
			this.getSalary()
			this.getProfession()
		},

		methods: {
			getSendAndPush() {
				let data = {
					professionId: this.professionId,
					condition: this.condition
				}
				this.jobNum.xdatas = []
				this.jobNum.seriesdata[0] = []
				this.jobNum.seriesdata[1] = []
				this.jobNum.seriesdata[2] = []

				apis.getSendAndPush(data).then(res => {
					if (this.condition == 0) {
						this.jobNum.xdatas = ['当前周', '近一周', '近两周', '近三周', '近四周']
						this.jobNum.xdatas = this.jobNum.xdatas.reverse()
					} else {
						// console.log(res.data)
						res.data = res.data.reverse()
						let nowDate = new Date()
						let array = []
						let year = nowDate.getFullYear()
						let mon = nowDate.getMonth() + 2
						for (let i = 0; i < 5; i++) {
							mon = mon - 1
							if (mon <= 0) {
								year = year - 1
								mon = mon + 12
							}
							if (mon < 10) {
								mon = '0' + mon
							}
							array[i] = year + '-' + mon
						}
						this.jobNum.xdatas = array
						this.jobNum.xdatas = this.jobNum.xdatas.reverse()
					}
					for (let i = 0; i < res.data.length; i++) {
						// this.jobNum.xdatas.push(res.data[i].terms)
						this.jobNum.seriesdata[0].push(res.data[i].pushAmount)
						this.jobNum.seriesdata[1].push(res.data[i].pushSendAmount)
						this.jobNum.seriesdata[2].push(res.data[i].selfSendAmount)
					}
				})
			},
			conditionChange(e) {
				this.condition = e
				this.getSendAndPush()
			},
			getPushNum() {
				let data = {
					professionId: this.professionId,
					condition: 0
				}
				apis.getPushNum(data).then(res => {
					this.pushForm = res.data
				})
			},
			getSendNum() {
				let data = {
					professionId: this.professionId,
					condition: 0
				}
				apis.getSendNum(data).then(res => {
					this.sendForm = res.data
				})
			},
			getSend() {
				let data = {
					professionId: this.professionId,
					condition: 2
				}
				apis.getSend(data).then(res => {
					this.postInfoData = [{
							value: res.data.suitAmount,
							name: '已通过'
						},
						{
							value: res.data.unSuitAmount,
							name: '不合适'
						},
						{
							value: res.data.unSuitNoViewAmount + res.data.suitNoViewAmount,
							name: '未面试'
						},
						{
							value: res.data.suitViewNoPassAmount + res.data.unSuitViewNoPassAmount,
							name: '面试未通过'
						}
					]
				})
			},

			getGraduate() {

				// let show1;
				// let show2;
				// let show3;
				// let show4;


				let data = {
					professionId: this.professionId,
					grade: this.grade
				}
				apis.getGraduate(data).then(res => {
					this.jobData = [{
						value: Number(res.data.jobAmount),
						name: '已就业'
					}, {
						value: Number(res.data.jobSearchAmount),
						name: '求职中'
					}, {
						value: Number(res.data.examineAmount),
						name: '升学中'
					}, {
						value: Number(res.data.studyingAmount) + Number(res.data.internshipAmount),
						name: '其他'
					}]
					this.sum = Number(res.data.studyingAmount) + Number(res.data.internshipAmount) + Number(res
						.data.examineAmount) + Number(res.data.jobSearchAmount) + Number(res.data.jobAmount)

					this.studentAmount = res.data.studentAmount.toString().split('')
					res.data.examineRate = (res.data.examineRate * 100).toFixed(2)
					res.data.jobGroupOnlineRate = (res.data.jobGroupOnlineRate * 100).toFixed(2)
					res.data.jobGroupEmploymentRate = (res.data.jobGroupEmploymentRate * 100).toFixed(2)
					res.data.jobRate = (res.data.jobRate * 100).toFixed(2)
					res.data.emwrate = res.data.emwrate.toFixed(2)
					res.data.jobSearchRate = (res.data.jobSearchRate * 100).toFixed(2)
					// res.data.jobSearchRate= res.data.jobSearchRate.toFixed(2)
					res.data.othersRate = (res.data.othersRate * 100).toFixed(2)
					this.rateForm = res.data
					this.stuForm = res.data
				})
			},
			getGraduateDetail() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
				}
				this.jobInfoData.xdata = []
				this.jobInfoData.seriesdata = []
				this.preparationData.xdata = []
				this.preparationData.seriesdata = []
				apis.getGraduateDetail(data).then(res => {
					res.data.job = res.data.job.reverse()
					res.data.examine = res.data.examine.reverse()
					for (let i = 0; i < res.data.job.length; i++) {
						this.jobInfoData.xdata.push(res.data.job[i].studentStatus)
						this.jobInfoData.seriesdata.push(res.data.job[i].studentNum)
					}
					for (let i = 0; i < res.data.examine.length; i++) {
						this.preparationData.xdata.push(res.data.examine[i].studentStatus)
						this.preparationData.seriesdata.push(res.data.examine[i].studentNum)
					}


				})
			},
			getArea() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
				}
				apis.getArea(data).then(res => {
					// console.log(res)
					this.jobGoneData = res.data.map((item) => ({
						value: Number(item.studentNum),
						name: item.areaName
					}))
					// console.log(this.jobGoneData)
					// for (let i = 0; i < res.data.length; i++) {
					// 	res.data[i].value = res.data[i].studentNum
					// 	res.data[i].name = res.data[i].areaName
					// 	res.data[i].studentRate = (res.data[i].studentRate * 100).toFixed(2)
					// }
					// this.jobGoneData.seriesdata = res.data
					// console.log()
				})
			},
			// getGraduate() {
			// 	let data = {
			// 		professionId: this.professionId,
			// 		grade: 2019
			// 	}
			// 	apis.getGraduate(data).then(res => {
			// 		this.jobData[0].value = Number(res.data.jobAmount)
			// 		this.jobData[1].value = Number(res.data.jobSearchAmount)
			// 		this.jobData[2].value = Number(res.data.examineAmount)
			// 		this.jobData[3].value = Number(res.data.studyingAmount) + Number(res.data.internshipAmount)
			// 	})
			// },
			getSalary() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
				}
				apis.getSalary(data).then(res => {
					this.internshipData = [{
							value: res.data[0].partTwo,
							name: '3000-4000',
							label: {
								show: res.data[0].partTwo == 0 ? false : true
							},
						},
						{
							value: res.data[0].partThree,
							name: '4000-6000',
							label: {
								show: res.data[0].partThree == 0 ? false : true
							},
						},
						{
							value: res.data[0].partFour,
							name: '6000-8000',
							label: {
								show: res.data[0].partFour == 0 ? false : true
							},
						}, {
							value: res.data[0].partOne,
							name: '3000以下',
							label: {
								show: res.data[0].partOne == 0 ? false : true
							},
						},

						{
							value: res.data[0].partFive,
							name: '8000以上',
							label: {
								show: res.data[0].partFive == 0 ? false : true
							},
						}
					]
					this.regularData = [{
							value: res.data[1].partTwo,
							name: '3000-4000',
							label: {
								show: res.data[1].partTwo == 0 ? false : true
							},
						},
						{
							value: res.data[1].partThree,
							name: '4000-6000',
							label: {
								show: res.data[1].partThree == 0 ? false : true
							},
						},
						{
							value: res.data[1].partFour,
							name: '6000-8000',
							label: {
								show: res.data[1].partFour == 0 ? false : true
							},
						},
						{
							value: res.data[1].partOne,
							name: '3000以下',
							label: {
								show: res.data[1].partOne == 0 ? false : true
							},
						},
						{
							value: res.data[1].partFive,
							name: '8000以上',
							label: {
								show: res.data[1].partFive == 0 ? false : true
							},
						}
					]
				})
			},
			getProfession() {
				let data = {
					professionId: this.professionId,
					grade: this.grade
				}
				apis.getProfession(data).then(res => {
					this.majorCounterpartData = res.data.toFixed(2)
					// console.log
				})
			},


			postNum() {
				this.postNums = this.$echarts.init(document.getElementById("postNumEcharts")); //获取dom
				new postNumEcharts(this.postNums, this.$echarts, this.jobNum.xdatas, this.jobNum
					.seriesdata);
			},
			postInfo() {
				this.postInfos = this.$echarts.init(document.getElementById("postInfoEcharts")); //获取dom
				new postInfoEcharts(this.postInfos, this.$echarts, this.postInfoData);
			},
			jobInfo() {
				this.jobInfos = this.$echarts.init(document.getElementById("jobInfoEcharts")); //获取dom
				new jobInfoEcharts(this.jobInfos, this.$echarts, this.jobInfoData.xdata, this.jobInfoData
					.seriesdata);
			},
			preparation() {
				this.preparations = this.$echarts.init(document.getElementById("preparationEcharts")); //获取dom
				new preparationEcharts(this.preparations, this.$echarts, this.preparationData.xdata, this
					.preparationData
					.seriesdata);
			},
			// jobGone() {
			// 	new jobGoneEcharts(this.$echarts, "jobGoneEcharts", this.jobGoneData.seriesdata);
			// },
			job() {
				this.jobs = this.$echarts.init(document.getElementById("jobEcharts")); //获取dom
				new jobEcharts(this.jobs, this.$echarts, this.jobData, this.sum);
			},
			salary() {
				this.salarys = this.$echarts.init(document.getElementById("salaryEcharts")); //获取dom
				new salaryEcharts(this.salarys, this.$echarts, this
					.regularData, this.internshipData);
			},
			majorCounterpart() {
				this.majorCounterparts = this.$echarts.init(document.getElementById("majorCounterpartEcharts")); //获取dom
				new majorCounterpartEcharts(this.majorCounterparts, this.$echarts, this.majorCounterpartData);
			},
			// 地图
			map() {
				let that = this
				this.mapChart = echarts.init(that.$refs.receivinges);
				window.onresize = this.mapChart.resize;
				this.mapChart.setOption({ // 进行相关配置
					tooltip: {
						trigger: 'item',
						enterable: true,
						formatter: function(parms) {
							var htmlText = ''
							if (isNaN(parms.value)) {
								htmlText = `<div>
									<div>${parms.name}</div>
								</div>`

							} else {
								htmlText = `<div>
									<div>${parms.name}:${parms.value}人</div>
								</div>`

							}
							return htmlText
							// 循环处理数据，展示数据
							// console.log(parms)


						},
					},
					grid: {
						left: '1%',
						right: '1%',
						top: '1%',
						bottom: '1%',
					},
					visualMap: {
						min: 0, // 最小值
						max: 50, // 最大值
						text: ['50', '0'],
						realtime: false,
						calculable: false,
						inRange: {
							color: ['#577bff', '#4c6fe0', '#405ec1'] // 渐变颜色
						}
					},
					series: [{
						name: "",
						type: 'map',
						mapType: "山东",
						label: {
							normal: {
								show: true, //显示省份标签
								textStyle: {
									color: "#fff",
									fontSize: 14
								} //省份标签字体颜色
							},
							emphasis: { //对应的鼠标悬浮效果
								show: false,
								textStyle: {
									color: "#fff"
								}
							}
						},
						aspectScale: 0.75,
						zoom: 1.2,
						itemStyle: {
							normal: {
								// borderColor: 'rgba(147, 235, 248, 0.6)',
								borderWidth: 1, //边际线大小
								borderColor: '#00FFF3', //边界线颜色
								areaColor: { //地图色
									type: 'linear',
									x: 0,
									y: 1,
									x2: 0,
									y2: 0,
									colorStops: [{
										offset: 0,
										color: '#91a6d3' // 0% 处的颜色
									}, {
										offset: 1,
										color: '#46a0ff' // 100% 处的颜色
									}],
									global: false, // 缺省为 false
								},

							},
							emphasis: {
								show: true,
								areaColor: '#86cbff', //鼠标滑过区域颜色
								label: {
									show: true,
									textStyle: {
										color: '#fff'
									}
								}
							}
						},
						data: this.jobGoneData
					}],
					// dataRange: {
					// 	x: '-100000 px', //图例横轴位置
					// 	y: '-100000 px', //图例纵轴位置

					// }

				})
				this.mapChart.on('mouseover', function(value) {
					that.name = value.name.split('市')[0]
					// that.dutyPhone = "12345678902"
					// that.sectionPhone = "12345678902"
					// that.presidentPhone = "12345678902"

				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.screen {
		width: 100%;
		height: calc(100% - 80px);
		display: flex;
		justify-content: space-between;
		background-image: url("../assets/jobBg.png");
		background-size: 80% 80%;
		background-repeat: no-repeat;
		background-position: center certer;
		overflow: hidden;

		>div {
			height: 100%;
		}

		>.left {
			width: 27%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			>.jobNum {
				width: 100%;
				height: 39%;
				padding: 20px;
				// background-image: url("../assets/border.png");
				// background-size: 100% 100%;
				// background-position: center center;

				>.num {
					width: 100%;
					display: flex;
					justify-content: space-around;
					height: 20%;
					color: #fff;

					>div {
						display: flex;

						>div:nth-of-type(1) {
							height: 65px;
							width: 85px;
							background-image: url("../assets/icon1.png");
							background-size: 70% 100%;
							background-position: left center;
							background-repeat: no-repeat;
							line-height: 70px;
							text-align: right;
							font-size: 14px;
						}

						>div:nth-of-type(2) {
							margin-top: 20px;
							margin-left: 10px;

							>p:nth-of-type(1) {
								font-size: 33px;

								>span {
									font-size: 14px;
									color: #86909C;
								}
							}

							>p:nth-of-type(2) {

								.el-icon-caret-bottom,
								.el-icon-caret-top {
									color: #F53F3F;
								}

								>span:nth-of-type(1) {
									font-size: 14px;
									color: #F53F3F;
									margin-right: 5px;
								}

								>span:nth-of-type(2) {
									font-size: 14px;
									color: #86909C;
								}
							}
						}
					}
				}
			}

			>.postInfo {
				width: 100%;
				height: 29%;
				padding: 20px;
				// background-image: url("../assets/border.png");
				// background-size: 100% 100%;
				// background-position: center center;
			}

			>.companyJob {
				width: 100%;
				height: 29%;
				// padding: 20px;
				// border: 2px solid #0098FA;
				border-radius: 8px;

				>div:nth-of-type(2) {
					display: flex;
					justify-content: space-around;
					margin-top: 20px;

					>div {
						width: 35%;
						height: 200px;
						background-image: url("../assets/job.png");
						background-size: 100% 100%;
						background-position: center center;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						padding-top: 50px;
						cursor: pointer;

						>p:nth-of-type(1) {
							font-size: 18px;
							color: #C5C5C5;
							// margin-top: 50px;
						}

						>p:nth-of-type(2) {
							font-size: 24px;
							color: #01C2FF;
							margin-top: 10px;

							>span {
								font-size: 14px;
								color: #C5C5C5;
							}
						}

						// height: 151px;
					}

					>div:nth-of-type(1) {
						background-image: url("../assets/company.png");
					}

					>div:hover {
						transform: scale(1.1);
					}
				}
			}
		}

		>.center {
			width: 45%;
			// display: flex;
			// flex-direction: column;
			// justify-content: space-around;

			>.school {
				height: 8%;
				text-align: center;
				display: flex;
				flex-direction: column;
				// justify-content: center;
				// align-items: center;

				>p {
					font-size: 20px;
					font-weight: bold;
					color: #129BFF;
					background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-fill-color: transparent;
					padding-top: 10px;
				}

				>.stunum {
					display: flex;
					align-items: flex-end;
					justify-content: center;
					text-align: center;

					>div {
						display: flex;
						justify-content: center;
						margin-top: 10px;

						>div {
							background-image: url("../assets/numbg.png");
							background-size: 100% 100%;
							background-position: center center;
							width: 46px;
							height: 46px;
							text-align: center;
							line-height: 46px;
							color: #00FFF3;
							font-size: 30px;
							font-weight: 550;
							margin: 0 5px;
						}
					}

					>span {
						font-weight: bold;
						font-size: 20px;
						color: #129BFF;
						background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}

				}

			}

			>.status {
				width: 100%;
				height: 52%;
				margin: auto;
				// padding-top: 20px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				>.subtitleCen {
					position: absolute;
					left: 0;
					top: -10px;
				}

				>.stuCon {
					position: relative;
					width: 100%;
					height: 100%;

					>div:nth-of-type(1) {
						position: absolute;
						left: 80px;
						top: 60px;
						background-image: url('../assets/num1.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 255px;
						height: 150px;
						display: flex;
						justify-content: space-between;
						line-height: 100px;
						padding: 0 30px 0 10px;

						>p {
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							padding-right: 8px;
						}

						>div {
							width: 60%;
							display: flex;
							justify-content: space-between;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #46AEF9;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #46AEF9;
								font-weight: 500;
							}
						}
					}

					.current1 {
						background-image: url('../assets/num5.png') !important;
					}

					>div:nth-of-type(2) {
						position: absolute;
						right: 20px;
						top: 0px;
						background-image: url('../assets/num2.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 255px;
						height: 150px;
						display: flex;
						justify-content: space-between;
						line-height: 100px;
						padding: 0 10px 0 30px;

						>p {
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							padding-left: 8px;
						}

						>div {
							width: 60%;
							display: flex;
							justify-content: space-between;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #46AEF9;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #46AEF9;
								font-weight: 500;
							}
						}
					}

					.current2 {
						background-image: url('../assets/num6.png') !important;
					}

					>div:nth-of-type(3) {
						position: absolute;
						left: 0px;
						bottom: 100px;
						background-image: url('../assets/num3.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 310px;
						height: 100px;
						display: flex;
						justify-content: space-between;
						line-height: 100px;
						padding: 0 30px 0 10px;

						>p {
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							padding-right: 65px;
						}

						>div {
							width: 50%;
							display: flex;
							justify-content: space-between;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #46AEF9;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #46AEF9;
								font-weight: 500;
							}
						}
					}

					.current3 {
						background-image: url('../assets/num8.png') !important;
					}

					>div:nth-of-type(4) {
						position: absolute;
						right: 0px;
						bottom: 30px;
						background-image: url('../assets/num4.png');
						background-size: 100% 100%;
						background-position: center center;
						width: 310px;
						height: 100px;
						display: flex;
						justify-content: space-between;
						line-height: 100px;
						padding: 0 10px 0 30px;

						>p {
							font-size: 18px;
							font-weight: 500;
							color: #FFFFFF;
							padding-left: 55px;
						}

						>div {
							width: 50%;
							display: flex;
							justify-content: space-between;

							>span:nth-of-type(1) {
								font-size: 24px;
								color: #46AEF9;
								font-weight: 500;
							}

							>span:nth-of-type(2) {
								font-size: 18px;
								color: #46AEF9;
								font-weight: 500;
							}
						}
					}

					.current4 {
						background-image: url("../assets/num7.png") !important;
					}
				}
			}

			>.rate {
				height: 10.5%;
				padding-top: 30px;

				>div {
					width: 200px;
					position: relative;

					>p:nth-of-type(1) {
						font-size: 18px;
					}

					>p:nth-of-type(2) {
						font-size: 23px;
						font-weight: 600;
						margin-top: 5px;
					}

					>p:nth-of-type(3) {
						font-size: 18px;
						color: #fff;
						position: absolute;
						right: -15px;
						top: -10px;
						padding: 0 5px;
						border-radius: 30px;
					}
				}

			}

			>.cenbottom {
				width: 100%;
				height: 29%;
				display: flex;
				justify-content: space-between;
				// margin-top:-10px;


				>.jobGones {
					width: 49.5%;
					height: 100%;
					// border: 2px solid #0098FA;
					border-radius: 8px;
				}
			}
		}

		>.right {
			width: 27%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;

			>div {
				width: 100%;
			}

			>.jobInfo,
			>.preparation {
				width: 100%;
				height: 39%;
				padding: 20px;
				// background-image: url("../assets/border.png");
				// background-size: 100% 100%;
				// background-position: center center;
			}

			>.preparation {
				height: 29%;
			}

			>.jobGone {
				width: 100%;
				height: 29%;
				// border: 2px solid #0098FA;
				border-radius: 8px;
			}
		}
	}

	.subtitle {
		width: 100%;
		height: 33px;
		display: flex;
		position: relative;
		align-items: center;

		>p {
			font-size: 15px;
			font-weight: bold;
			height: 33px;
			background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
			background-clip: text;
			text-fill-color: transparent;
			line-height: 33px;
			color: transparent;
		}

		>div {
			width: 100%;
			display: flex;
			justify-content: space-between;

			>p {
				font-size: 15px;
				font-weight: bold;
				height: 33px;
				background: linear-gradient(180deg, #55CFFF 0%, #0987FD 100%);
				background-clip: text;
				text-fill-color: transparent;
				line-height: 33px;
				color: transparent;
			}
		}

		>span {
			display: block;
			width: 3.85px;
			height: 15.41px;
			background: #3FB2F9;
			margin-right: 10px;
			border-radius: 30px;
		}

		>img {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
		}
	}

	.subtitle2 {
		background: #0098FA;
		width: 84px;
		height: 25px;
		text-align: center;
		line-height: 25px;
		border-radius: 8px 0px 8px 0px;
		font-size: 12px;
	}

	::v-deep .el-input__inner {
		background: #0A469E !important;
		border: 0.91px solid #0A469E !important;
		color: #fff !important;
	}

	.bgimg {
		background: no-repeat center;
		background-image: url("~@/assets/jobcenBg.png");
		background-size: 100% 100%;
		background-position: center bottom;
		width: 90%;
		height: 140%;
		position: absolute;
		z-index: 0;
		margin-top: 40px;
	}

	@-webkit-keyframes rotation {

		0% {
			transform: translateX(0%) rotateX(0deg) rotateZ(0)
		}

		100% {
			transform: translateX(0%) rotateX(0deg) rotateZ(360deg)
		}

	}

	.an {
		// animation: rotation 20s linear infinite;
	}
</style>
<style type="text/css">
	.el-select-dropdown {
		background: #0A469E !important;
		color: #fff !important;
		border: 0.91px solid #0A469E !important;
		font-size: 12px !important;
		/* height: 20px !important;
		line-height: 20px !important; */

	}

	.el-select-dropdown__item {
		color: #fff !important;
		font-size: 12px !important;
		background: #0A469E !important;
	}

	.el-select-dropdown__item.hover {
		color: #fff !important;
		background: #0A469E !important;
		font-size: 12px !important;

	}
</style>