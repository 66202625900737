import {
	autoHover
} from './tooltip.js'
export default class {
	constructor(charts,echarts, stuData) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(stuData) //把参数传递给方法
		// var charts = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		autoHover(charts, stuData, 2000)
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(resultData) {
		// var xData = stuData.xdata;
		// var data0 = [1, 1, 1, 1, 1];
		// var data1 = stuData.data1 //实际招生
		// var data4 = stuData.data2; //计划招生

		// var getvalue1 = stuData.result[2]; // 招生率
		// var data1 = [10, 50, 100, 35, 55]; //上衣
		// var data4 = [15, 25, 90, 40, 26]; //裤子
		// var getvalue1 = [40, 85, 230, 95, 100];
		// var data3 = [];
		// var data5 = [];
		// for (let i = 0; i < data1.length; i++) {
		// 	data3.push(data1[i] + data4[i]);
		// }
		// for (let i = 0; i < data1.length; i++) {
		// 	data5.push(data1[i]);
		// }
		const barWidth = 20;
		const colors = [];
		const colors1 = [];
		for (let i = 0; i < resultData.length; i += 1) {
			colors.push({
				type: 'linear',
				x: 0,
				x2: 1,
				y: 0,
				y2: 0,
				colorStops: [{
						offset: 0,
						color: '#e7a100', // 最左边
					},
					{
						offset: 0.5,
						color: '#c17f00', // 左边的右边 颜色
					},
					{
						offset: 0.5,
						color: '#e6a100', // 右边的左边 颜色
					},
					{
						offset: 1,
						color: '#e7a100',
					},
				],
			});
			colors1.push({
				type: 'linear',
				x: 0,
				x2: 1,
				y: 0,
				y2: 0,
				colorStops: [{
						offset: 0,
						color: '#1cb4f2', // 最左边
					},
					{
						offset: 0.5,
						color: '#0071b5', // 左边的右边 颜色
					},
					{
						offset: 0.5,
						color: '#0089d0', // 右边的左边 颜色
					},
					{
						offset: 1,
						color: '#00497c',
					},
				],
			});
		}

		let option = {
			animationDuration: 2800, //加上这个
			animationEasing: "quadraticOut", //加上这个

			tooltip: {
				axisPointer: {
					type: 'shadow'
				},
				position: 'top',
				trigger: 'axis',
				borderColor: '#28F1FA',
				backgroundColor: 'rgba(0, 13, 58, 0.35)',
				textStyle: {
					color: 'white', //设置文字颜色
				},
				borderWidth: 1,
				padding: 5,
				className: 'custom-tooltip',
				formatter: function(parms) {
					// 循环处理数据，展示数据
					// console.log(parms)
					var htmlText = `<div style="width: 120px;height: 100px;">
						<div style='font-size:18px;color:#28F1FA'>${parms[0].value.grade}</div>
						<div>实际招生：${parms[0].value.readingStudent}</div>
						<div>计划招生：${parms[0].value.initStudent}</div>
						<div>招生率：${parms[0].value.rate}</div>
					</div>`
					return htmlText

				},
			},
			textStyle: {
				color: '#C9C9C9',
			},

			// color: ['#fbc292', '#06fbfe',  '#f06e91'],

			grid: {
				containLabel: true,
				left: '18%',
				top: '10%',
				bottom: '10%',
				right: '25%',
			},
			color: ['#ffb40a', '#1cb4f2'],
			dataset: [{
				source: resultData,
				dimensions: ['grade', 'initStudent', 'readingStudent'],
			}, ],
			// X轴
			xAxis: {
				show: false,
				type: 'category',
				axisLine: {
					show: false,
					lineStyle: {
						color: '#004B97',
					},
				},
				splitLine: {
					show: false,
				},
				axisTick: {
					show: false,
				},
				
			},
			yAxis: {
				show: false,
				
				axisLine: {
					show: false,
					lineStyle: {
						color: '#004B97',
					},
				},
				splitLine: {
					show: false,
					lineStyle: {
						type: 'dashed',
						color: '#004B97',
					},
				},
				axisLabel: {
					color: '#FFFFFF',
					// margin: 30,
					// fontSize: 13,
				},
			},
			series: [{
					type: 'bar',
					name: '计划招生',
					barWidth,
					itemStyle: {
						normal: {
							color(params) {
								return colors[params.dataIndex % 7];
							},
						},
					},
					label: {
						show: false,
						// position: [barWidth / 2, -(barWidth + 5)],
						// color: '#ffffff',
						// fontSize: 15,
						// fontStyle: 'bold',
						// align: 'center',
					},
					encode: {
						x: 'grade',
						y: 'initStudent',
					},
				},

				{
					z: 2,
					type: 'pictorialBar',
					encode: {
						x: 'grade',
						y: 'initStudent',
					},
					symbol: 'diamond',
					symbolOffset: ['-63%', '50%'],
					symbolSize: [barWidth, barWidth * 0.5],
					itemStyle: {
						normal: {
							color(params) {
								return colors[params.dataIndex % 7];
							},
						},
					},
				},
				{
					z: 3,
					type: 'pictorialBar',
					symbolPosition: 'end',
					encode: {
						x: 'grade',
						y: 'initStudent',
					},
					symbol: 'diamond',
					symbolOffset: ['-63%', '-50%'],
					symbolSize: [barWidth, barWidth * 0.5],
					itemStyle: {
						normal: {
							borderWidth: 0,
							color(params) {
								return colors[params.dataIndex % 7].colorStops[0].color;
							},
						},
					},
				},
				{
					type: 'bar',
					name: '实际招生',
					barWidth,
					itemStyle: {
						normal: {
							color(params) {
								return colors1[params.dataIndex % 7];
							},
						},
					},
					label: {
						show: false,
						// position: [barWidth / 2, -(barWidth + 5)],
						// color: '#ffffff',
						// fontSize: 15,
						// fontStyle: 'bold',
						// align: 'center',
					},
					encode: {
						x: 'grade',
						y: 'readingStudent',
					},
				},
				{
					z: 2,
					type: 'pictorialBar',
					encode: {
						x: 'grade',
						y: 'readingStudent',
					},
					symbol: 'diamond',
					symbolOffset: ['63%', '50%'],
					symbolSize: [barWidth, barWidth * 0.5],
					itemStyle: {
						normal: {
							color(params) {
								return colors1[params.dataIndex % 7];
							},
						},
					},
				},

				{
					z: 3,
					type: 'pictorialBar',
					symbolPosition: 'end',
					encode: {
						x: 'name',
						y: 'readingStudent',
					},
					symbol: 'diamond',
					symbolOffset: ['62%', '-50%'],
					symbolSize: [barWidth, barWidth * 0.5],
					itemStyle: {
						normal: {
							borderWidth: 0,
							color(params) {
								return colors1[params.dataIndex % 7].colorStops[0].color;
							},
						},
					},
				},
			],
		};

		return option;
	}
}