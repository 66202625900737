export default class {
	constructor(charts, echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});

	}
	getOption(seriesdata) {
		// function fontSize(res) {
		// 	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body
		// 		.clientWidth;
		// 	if (!clientWidth) return;
		// 	let fontSize = 100 * (clientWidth / 1920);
		// 	return res * fontSize;
		// }
		let option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				},
				formatter: function(parms) {
					var htmlText = `<div">
						<div style='font-size:18px;color:#6fe5ff'>${parms[0].axisValueLabel}</div>
						<div>已完成：${parms[0].value}课时</div>
						<div>课时总数：${parms[1].value}课时</div>
					</div>`
					return htmlText
				},

			},
			grid: {
				left: '1%',
				right: '1%',
				bottom: '1%',
				containLabel: true
			},
			xAxis: [{
				type: 'category',
				data: seriesdata.xdata,
				axisPointer: {
					type: 'shadow'
				},
				axisLine: {
					lineStyle: {
						color: "rgba(78, 202, 217, 0.4)"
					}
				},
				axisLabel: {
					interval: 0,
					textStyle: {
						fontSize: 14,
						color: '#fff',
					},
					rotate: 45,
					formatter: function(params) {
						// 超过多少字隐藏
						var val = "";
						//超过六个字隐藏
						if (params.length > 4) {
							//截取六个字
							val = params.substr(0, 4) + '...';
							return val
						} else {
							return params

						}
					}
				},
				// axisLabel: {
				// 	// 旋转角度
				// 	rotate: 45
				// }

			}],
			yAxis: [{
				type: 'value',
				name: '课时数',
				axisLine: {
					show: true,
					lineStyle: {
						fontSize: 14,
						color: 'rgba(255,255,255,0)',
					}

				}, //左线色
				axisLabel: {
					textStyle: {
						color: '#fff',
						fontSize: 14,
					},
					formatter: '{value}'
				},
				splitLine: { //决定是否显示坐标中网格
					// show: false,
					lineStyle: {
						color: ['rgba(255,255,255,0.5)'],
						type: 'dot'
					}
				},
			}],
			series: [
				// {
				// 	name: '课时总数',
				// 	type: 'bar',
				// 	emphasis: {
				// 		focus: 'series'
				// 	},
				// 	data: seriesdata.result[0],
				// 	itemStyle: {
				// 		normal: {
				// 			color: '#86D0FF'
				// 		}
				// 	}
				// },
				// {
				// 	name: '已完成',
				// 	type: 'bar',
				// 	z:3,
				// 	emphasis: {
				// 		focus: 'series'
				// 	},
				// 	data: seriesdata.result[1],
				// 	itemStyle: {
				// 		normal: {
				// 			color: '#0098FA'
				// 		}
				// 	}
				// },
				{
					name: "已完成",
					type: 'bar',
					barWidth: "30%",
					z: 3, //让实时在总计上面
					data: seriesdata.result[1],
					itemStyle: {
						normal: {
							color: '#0098FA'
						}
					}
				},
				{
					name: "课时总数",
					type: 'bar',
					barWidth: "30%",
					barGap: "-100%",
					data: seriesdata.result[0],
					label: {
						show: false,
					},
					itemStyle: {
						normal: {
							color: '#86D0FF'
						}
					}
				}
			]
		};

		return option;
	}
}