export default class {
	constructor(charts,echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		console.log(seriesdata, 'seriesdata')
		let option = this.getOption(echarts, seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(echarts, value) {
		// function fontSize(res) {
		// 	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body
		// 		.clientWidth;
		// 	if (!clientWidth) return;
		// 	let fontSize = 100 * (clientWidth / 1920);
		// 	return res * fontSize;
		// }
		let option = {
			// title: {
			// 	text: '{a|' + value * 100 + '%}',
			// 	x: 'center',
			// 	y: 'center',
			// 	textStyle: {
			// 		rich: {
			// 			a: {
			// 				fontSize: 36,
			// 				color: '#fff'
			// 			},
			// 		}
			// 	}
			// },
			series: [{
					//进度格主体样式
					type: 'gauge',
					radius: '80%',
					clockwise: true,
					startAngle: '90',
					endAngle: '-269.999',
					splitNumber: 12,
					detail: {
						show: false
					},
					pointer: {
						show: false
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: [
								[1, 'rgba(32,187,252,0.15)']
							],
							width: 30
						}
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: true,
						length: 32,
						lineStyle: {
							color: '#051F54',
							width: 8
						}
					},
					axisLabel: {
						show: false
					}
				},		
				{
					//进度格渐变样式
					name: "",
					type: "pie",
					radius: ["58%", "80%"],
					center: ['50%', '50%'],
					hoverAnimation: false,
					silent: false,
					clockwise: true,
					startAngle: 90,
					z: 0,
					zlevel: 0,
					label: {
						show: false
					},
					data: [{
							value: value * 360,
							name: "",
							label: {
								show: false
							},
							itemStyle: {
								
								color: new echarts.graphic.LinearGradient(0, 0, 0, value, [{
									offset: 0,
									color: '#1885FF'
								}, {
									offset: value,
									color: '#1B7EF2'
								}])

							}
						},
						{
							value: 360 * (1 - value),
							name: "",
							label: {
								normal: {
									show: false
								}
							},
							itemStyle: {
								normal: {
									color: "#001B3A"
								}
							}
						}
					]
				},
			]
		};

		return option;
	}
}