export default class {
	constructor(charts,echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(seriesdata) {
		// function fontSize(res) {
		// 	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body
		// 		.clientWidth;
		// 	if (!clientWidth) return;
		// 	let fontSize = 100 * (clientWidth / 1920);
		// 	return res * fontSize;
		// }
		let option = {
			tooltip: {
				trigger: 'item'
			},
			legend: {
				left: 'left',
				textStyle: {
					color: "#fff",
					fontSize:14
				},
				orient: 'vertical',
				itemWidth: 12,
				itemHeight: 12,
			},
			// grid: {
			// 	top: "4%",
			// 	right: "0%",
			// 	bottom: "0%",
			// 	left: "0%",
			// 	containLabel: true
			// },
			series: [{
				label: {
					position: 'inner',
					fontSize: 14,
					formatter: `{d}%`,
				},
				name: '简历投递详情',
				type: 'pie',
				radius: [40, 100],
				center: ['50%', '50%'],
				roseType: 'area',
				itemStyle: {
					borderColor: '#11123a',
					borderWidth: 5,
					color: function(colors) {
						var colorList = [

							'#F67FA8',
							'#86E1F6',
							'#9BEF59',
							'#7C8AF9',
							'#f9c956',
							'#75bedc'
						];
						return colorList[colors.dataIndex];
					}
				},
				data: seriesdata
			}]
		};

		return option;
	}
}