export default class {
	constructor(charts,echarts, regularData, internshipData) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(regularData, internshipData) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(regularData, internshipData) {
		// function fontSize(res) {
		// 	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body
		// 		.clientWidth;
		// 	if (!clientWidth) return;
		// 	let fontSize = 100 * (clientWidth / 1920);
		// 	return res * fontSize;
		// }
		let option = {
			tooltip: {
				trigger: 'item',
				formatter: '{a} <br/>{b}: {c} ({d}%)'
			},
			legend: {
				left: 'left',
				orient: 'vertical',
				textStyle: {
					color: "#fff",
					fontSize: '12px'
				},
				itemWidth: 12,
				itemHeight: 12,
				data: [
					'3000以下',
					'3000-4000',
					'4000-6000',
					'6000-8000',
					'8000以上',
				]
			},
			title: [{
				text: '转正薪资',
				subtext: '实习薪资',
				left: 'center',
				textStyle: {
					color: '#ffffff',
					fontWeight: 'normal',
					fontSize: 14,
					lineHeight: 0
				},
				subtextStyle: {
					color: '#ffffff',
					fontWeight: 'normal',
					fontSize: 14
				},
				itemGap: 120

			}, ],
			series: [{

					name: '转正薪资',
					type: 'pie',
					selectedMode: 'single',
					radius: ['60%', '90%'],
					center: ["50%", "53%"],
					label: {
						position: 'inner',
						fontSize: 14,
						formatter: function(data) {
							// console.log(data,'datadata')
							let b = data.percent.toFixed(0) + "%"
							return b
						},
					},
					labelLine: {
						show: false
					},
					itemStyle: {
						borderRadius: 8,
						color: function(colors) {
							var colorList = [

								'#0098FA',
								'#86E1F6',
								'#8895FA',
								'#F4D925',
								'#EB8169',
								'#75bedc'
							];
							return colorList[colors.dataIndex];
						}
					},
					data: regularData
				},
				{
					name: '实习薪资',
					type: 'pie',
					radius: ['25%', '45%'],
					center: ["50%", "53%"],
					labelLine: {
						show: false
					},
					label: {
						// show:function(val){
						// 	// console.log(val,'1111')
						// 	if(val.data.value==0){
						// 		return false
						// 	}else{
						// 		return true
						// 	}
						// },
						position: 'inner',
						fontSize: 14,
						formatter: function(data) {
							let b = data.percent.toFixed(0) + "%"
							return b
						},
					},
					itemStyle: {
						borderRadius: 8,
						color: function(colors) {
							var colorList = [

								'#0098FA',
								'#86E1F6',
								'#8895FA',
								'#F4D925',
								'#EB8169',
								'#75bedc'
							];
							return colorList[colors.dataIndex];
						}
					},
					data: internshipData
				}
			]
		};

		return option;
	}
}