export default class {
	constructor(charts,echarts, xdata, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(xdata, seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(xdata, seriesdata) {
		// function fontSize(res) {
		// 	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body
		// 		.clientWidth;
		// 	if (!clientWidth) return;
		// 	let fontSize = 100 * (clientWidth / 1920);
		// 	return res * fontSize;
		// }
		let option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'none',
				},

			},

			grid: {
				top: "0%",
				right: "5%",
				bottom: "0%",
				left: "5%",
				containLabel: true
			},
			xAxis: {
				show: false
			},
			yAxis: {
				type: 'category',
				data: xdata,
				splitLine: { //决定是否显示坐标中网格
					show: false,
				},
				axisLine: {
					lineStyle: {
						fontSize:14,
						color: 'rgba(255,255,255,0)',
					}
				}, //左线色
				axisLabel: {
					textStyle: {
						color: '#fff',
						fontSize: 14,
					},
					formatter: '{value}'
				},
			},
			series: [{
				name: '',
				type: 'bar',
				barWidth: 25,
				tooltip: {
					valueFormatter: function(value) {
						return value;
					}
				},
				itemStyle: {
					normal: {
						color: function(colors) {
							var colorList = [
								'#FFD5D7',
								'#D8E3FF',
								'#FFD5D7',
								'#D8E3FF',
								'#FFD5D7',
								'#D8E3FF',
							];
							return colorList[colors.dataIndex];
						},

					}
				},
				label: {
					normal: {
						show: true,
						formatter: function(data) {
							if (data.dataIndex == 0 || data.dataIndex == 2) {
								if(data.value<=0){
									return '{a|' + data.value + '}'
								}else{
									return '{a|' + data.value + '人}'
								}
								
							} else {
								if(data.value<=0){
									return '{b|' + data.value + '}'
								}else{
									return '{b|' + data.value + '人}'
								}
								
							}
						},
						rich: {
							a: {
								color: '#FD6F81',
								marginLeft:'10px'
							},
							b: {
								color: '#6EA4FC',
								marginLeft:'10px'
							}
						}
					},
					show: true, //开启显示
					position: 'insideBottom', //在上方显示
					textStyle: { //数值样式
						fontSize: 14,
						
					},
					


				},
				data: seriesdata
			}, ]
		};

		return option;
	}
}