export default class {
	constructor(charts, echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		// console.log(seriesdata, 'seriesdata')
		let option = this.getOption(echarts, seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(echarts, seriesdata) {
		let option = {
			grid: {
				left: '5%',
				right: '10%',
				bottom: '5%',
				top: '5%',
				// containLabel: true
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'none'
				},
				formatter: '{b}：{c}%'
			},
			xAxis: [{
					show: false,
					type: 'value'
				},
				{
					show: false,
				}
			],
			// yAxis: [
			yAxis: {
				type: 'category',
				inverse: 'true', //排序,
				data: seriesdata.xdata
			},
			series: [{
					type: 'bar',
					barWidth: 10,
					// barWidth: '15px', //条条宽度
					showBackground: false,
					backgroundStyle: {

					},
					label: {
						show: true,
						position: 'right', //每一条的数据位置
						color: 'rgba(255, 255, 255, 0.64)',
						formatter: '{c}%',
					},
					itemStyle: {

						normal: {
							color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
								offset: 0,
								color: 'rgba(27, 126, 242, 0)' // 0% 处的颜色
							}, {
								offset: 1,
								color: '#1B7EF2' // 100% 处的颜色
							}], false),
							// barBorderRadius: [20, 20, 20, 20], //圆角
							shadowBlur: 10,
							shadowColor: 'rgba(0, 103, 255, 0.2)',
							shadowOffsetX: -5,
							shadowOffsetY: 5,

						},

					},
					data: seriesdata.data
				},
				{ //名称
					show: true,
					type: 'bar',
					xAxisIndex: 1, //代表使用第二个X轴刻度
					barGap: '-100%',
					barWidth: '25%',
					itemStyle: {
						normal: {
							barBorderRadius: 200,
							color: 'transparent'
						}
					},
					label: {
						normal: {
							show: true,
							position: [0, '-25'],
							textStyle: {
								fontSize: 14,
								color: '#fff',
								padding: [0, 10]
							},
							formatter: function(data) {
								return seriesdata.xdata[data.dataIndex];
							}
						}
					},
					data: seriesdata.data
				}
			]
		};

		return option;
	}
}