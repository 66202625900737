export default class {
	constructor(charts,echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});

	}
	getOption(seriesdata) {
		// function fontSize(res) {
		// 	let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body
		// 		.clientWidth;
		// 	if (!clientWidth) return;
		// 	let fontSize = 100 * (clientWidth / 1920);
		// 	return res * fontSize;
		// }
		let option = {
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					crossStyle: {
						color: '#999'
					}
				},
				formatter: function(parms) {
					var htmlText = `<div">
						<div style='font-size:18px;color:#6fe5ff'>${parms[0].axisValueLabel}</div>
						<div>课时总数：${parms[0].value}课时</div>
						<div>已完成：${parms[1].value}课时</div>
						<div>完成率：${parms[2].value}%</div>
					</div>`
					return htmlText
				},
			},
			grid: {
				// top: "10%",
				right: "1%",
				bottom: "3%",
				left: "1%",
				containLabel: true
			},
			legend: {
				left: 'right',
				textStyle: {
					color: "#fff",
					fontSize: 14
				},
				itemWidth: 12,
				itemHeight: 12,
				data: ['课时总数', '已完成', '完成率'],

			},
			xAxis: [{
				type: 'category',
				data: seriesdata.xdata,
				axisPointer: {
					type: 'shadow'
				},
				axisLine: {
					lineStyle: {
						color: "rgba(78, 202, 217, 0.4)"
					}
				},
				axisLabel: {
					interval: 0,
					textStyle: {
						fontSize: 14,
						color: '#fff',
					},
					rotate: 45,
					formatter: function(params) {
						// 超过多少字隐藏
						var val = "";
						//超过六个字隐藏
						if (params.length > 4) {
							//截取六个字
							val = params.substr(0, 4) + '...';
							return val
						} else {
							return params
					
						}
					}
				},
			}],
			yAxis: [{
					type: 'value',
					name: '已完成',
					// min: 0,
					// max: 250,
					// interval: 50,
					axisLabel: {
						textStyle: {
							color: '#fff',
							fontSize: 14,
						},
						formatter: '{value} '
					},
					axisLine: {
						show: true,
						lineStyle: {
							fontSize: 14,
							color: 'rgba(255,255,255,0)',
						}

					}, //左线色
					splitLine: { //决定是否显示坐标中网格
						// show: false,
						lineStyle: {
							color: ['rgba(255,255,255,0.5)'],
							type: 'dot'
						}
					},
				},
				{
					type: 'value',
					name: '完成率',
					// min: 0,
					// max: 25,
					// interval: 5,
					axisLabel: {
						textStyle: {
							color: '#fff',
							fontSize: 14,
						},
						formatter: '{value} %'
					},
					axisLine: {
						show: true,
						lineStyle: {
							fontSize: 14,
							color: 'rgba(255,255,255,0)',
						}

					}, //左线色
					splitLine: { //决定是否显示坐标中网格
						show: false,

					},
				}
			],
			series: [{
					name: '课时总数',
					type: 'bar',
					data: seriesdata.result[0],
					itemStyle: {
						normal: {
							color: '#8895FA'
						}
					}
				},
				{
					name: '已完成',
					type: 'bar',

					data: seriesdata.result[1],

					itemStyle: {
						normal: {
							color: '#0CD9B5'
						}
					}
				},
				{
					name: '完成率',
					type: 'line',
					yAxisIndex: 1,
					symbolSize: 0,
					tooltip: {
						valueFormatter: function(value) {
							return value + ' %';
						}
					},
					data: seriesdata.result[2],
					itemStyle: {
						normal: {
							color: '#F4D925'
						}
					}
				}
			]
		};

		return option;
	}
}