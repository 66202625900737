export default class {
	constructor(charts,echarts, seriesdata) { //echarts, id, xdata,seriesdata都是vue组件中传递过来的参数
		this.echarts = echarts;
		let option = this.getOption(seriesdata) //把参数传递给方法
		// var myChart = echarts.init(document.getElementById(id)); //获取dom
		charts.setOption(option); //暴露出去
		window.addEventListener("resize", function() {
			charts.resize();
		});
	}
	getOption(seriesdata) {
		let option = {
			color: [
				'#F67FA8',
				'#9BEF59',
				'#f4d64e',
				'#0072ff'
			],
			tooltip: {
				trigger: 'item'
			},
			legend: {
				top:'5%',
				left: '5%',
				orient: "vertical",
				icon: "circle",
				textStyle: {
					color: "#fff",
					fontSize: 14,
				},
			},
			series: [{
				type: 'pie',
				radius: ['50%', '70%'],
				center: ["50%", "50%"],
				avoidLabelOverlap: false,
				label: {
					show: true,
					position: 'outside',
					formatter: function(data) {
						// console.log('data',data)
						return (
							'{value|'+data.value+'个' + '\n}' + ' \n{value|' + data.percent
							.toFixed(0) + '%}'
						);
					},
					rich: {
						name: {
							fontSize: 14,
							color: '#ffffff',
						},
						value: {
							fontSize: 14,
							color: '#ffffff',
						},
					},
				},
				labelLine: {
					show: false,
					normal: {
						length: 20,
						length2: 20,
						align: 'center',
						lineStyle: {
							width: 1,
						},
					},
				},
				data: seriesdata
			}],
		};


		return option;
	}
}